import { useFormikContext } from 'formik';
import React from 'react';
import { ShrinkIcon, ExpandIcon } from '../../../../../../../../shared/icons';
import { getIndex } from '../../../../../../../../shared/utils/createGallery';
import { ExpandAndShrinkWrapper } from './styled';
import { CreatorGalleryFile, FilesOrIdeas, GalleryIdea } from '../../../../../../../../shared/types/commonGallery';
import { ClientGallerySection } from '../../../../../../../../shared/types/clientGallery';
import { CreatorGalleryForm } from '../../../../../../../../shared/types/creatorGallery';
import { useUpdateFiles } from '../../../../../../../Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/hooks/useUpdateFiles';
import { PinkLoader } from '../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';

type ExpandAndShrinkFileButtonProps = {
  sectionId: string;
  id: string;
  fileType: FilesOrIdeas;
  fullWidth: boolean;
};

export const ExpandAndShrinkFileButton: React.FC<ExpandAndShrinkFileButtonProps> = ({
  sectionId,
  id,
  fileType,
  fullWidth,
}) => {
  const { values, setFieldValue } = useFormikContext<CreatorGalleryForm>();
  const indexSection = getIndex<ClientGallerySection>(values.sections, sectionId);
  const {
    updateFiles, isLoading, error, isSuccess,
  } = useUpdateFiles();

  const handleExpandShrink = (itemId: string, type: FilesOrIdeas) => () => {
    const indexFile = getIndex<CreatorGalleryFile | GalleryIdea>(
      values.sections[indexSection][type],
      itemId,
    );

    const isFullWidth = !values.sections[indexSection][type][indexFile].fullWidth;

    updateFiles([{ id: itemId, fullWidth: isFullWidth }]);

    setFieldValue(
      `sections[${indexSection}].${type}[${indexFile}].fullWidth`,
      isFullWidth,
    );
  };

  return (
    <>
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
      <ExpandAndShrinkWrapper
        type="button"
        onClick={handleExpandShrink(
          id,
          fileType,
        )}
      >
        {isLoading && <PinkLoader size="xs" />}
        {fullWidth && !isLoading ? <ShrinkIcon /> : <ExpandIcon />}
      </ExpandAndShrinkWrapper>
    </>
  );
};
