/* eslint-disable */
import { useState } from 'react';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../shared/hooks/useTypedSelector';
import {
  getUploadFileUrl,
  uploadFile,
  deleteFromErrorFiles,
  resetFiles,
  setErrorFiles,
} from '../redux/gallery/uploadFiles/uploadFilesSliceV2';
import { ErrorItem, FileRole, GalleryId, UploadUrlsResponseDto, UploadingItem } from '../types/gallery';
import { useAuth } from '@clerk/clerk-react';
import { CreatorGalleryFile } from '../../../shared/types/commonGallery';

export type OnUploadProps = {
  files: File[],
  galleryId: GalleryId,
  fileRole: FileRole,
  sectionId?: string,
  anchorFileId?: string | null,
  existedFiles?: CreatorGalleryFile[];
}

type ReturnObj = {
  files: File[];
  stateFiles: any;
  uploading: UploadingItem[];
  errorFiles: ErrorItem[];
  onUpload: (props: OnUploadProps) => void;
  setFiles: (files: File[]) => void;
  error: string | null;
  warning: string;
  isDuplicate: boolean;
};

export const useUploadFiles = (sectionId = ''): ReturnObj => {
  const [uploadFiles, setFiles] = useState<File[]>([]);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const uploading = useTypedSelectorCreator((state) => state.uploadFilesV2.uploading);
  const stateFiles = useTypedSelectorCreator((state) => state.uploadFilesV2.files);
  const errorFiles = useTypedSelectorCreator((state) => state.uploadFilesV2.errorFiles);
  const error = useTypedSelectorCreator((state) => state.uploadFilesV2.error);
  const warning = useTypedSelectorCreator((state) => state.uploadFilesV2.warning);
  const currentUploading = uploading.find((item) => item.sectionId === sectionId);
  const { getToken } = useAuth();

  const dispatch = useCreatorAppDispatch();

  const onUpload = async ({files, galleryId, fileRole, anchorFileId = null, sectionId = '', existedFiles = [] }: OnUploadProps) => {
    if (files && files.length) {
      setIsDuplicate(false);
      let lcFiles = files || [];
      const uploadingCurrent = uploading.find((item) => item.sectionId === sectionId);
      const errorCurrent = errorFiles.find((item) => item.sectionId === sectionId);

      if (uploadingCurrent) {
        lcFiles = lcFiles.filter((item) => {
          const existedFile = uploadingCurrent.files.find((file) => file.name === item.name);
          if (existedFile) {
            setIsDuplicate(true);
          }
          return !existedFile;
        });
      }
      if (errorCurrent) {
        lcFiles = lcFiles.filter((item) => {
          const existedFile = errorCurrent.files.find((file) => file.name === item.name);
          if (existedFile) {
            setIsDuplicate(true);
          }
          return !existedFile;
        });
      }
      if (existedFiles.length) {
        lcFiles = lcFiles.filter((item) => {
          const existedFile = existedFiles.find((file) => file.name === item.name);
          if (existedFile) {
            setIsDuplicate(true);
          }
          return !existedFile;
        });
      }

      if (lcFiles) {
        setFiles(prev => [...prev, ...lcFiles]);

      try {
        if (errorFiles.length) {
          dispatch(deleteFromErrorFiles({ files: lcFiles, sectionId }));
        }
        dispatch(resetFiles());
        const urlResponse = (await dispatch(
          getUploadFileUrl({ files: lcFiles, sectionId: sectionId, galleryId, getToken, role: fileRole }),
        )) as any;
        if (urlResponse.payload && !urlResponse.payload.error) {
          // проверить не пришла ли ошибка, приходят данные от облака
          (urlResponse.payload.files as UploadUrlsResponseDto).forEach(async (item) => {
            const name = Object.keys(item);
            const fileLocal = lcFiles.find((file) => file.name === name[0]);
          
            if (fileLocal && item[name[0]].errorMessage) {
              dispatch(setErrorFiles({
                files: [{ name: name[0], type: fileLocal.type, errorMessage: item[name[0]].errorMessage }], sectionId,
              }));
            }
            if (fileLocal && item[name[0]].id && item[name[0]].url && !item[name[0]].errorMessage) {
              const uploadResponse = await dispatch(uploadFile({
                file: fileLocal, url: item[name[0]].url, sectionId: sectionId, fileId: item[name[0]].id, fileRole, originalAwsKey: item[name[0]].originalAwsKey,
              }));
            }
          })
        }
      } catch (e) {
        console.log(e);
      }

      }
    }
  };
  const resUploading = currentUploading?.files || [];
  const resStateFiles = stateFiles.find((item) => item.sectionId === sectionId)?.files || [];
  const resErrorFiles = errorFiles.find((item) => item.sectionId === sectionId)?.files || [];

  return {
    files: uploadFiles,
    uploading: resUploading,
    onUpload,
    setFiles,
    stateFiles: resStateFiles,
    error,
    errorFiles: resErrorFiles,
    warning,
    isDuplicate,
  };
};
