import {
  CreatorGalleryFile,
  GalleryIdea,
} from '../../../../../../../shared/types/commonGallery';
import {
  isArrayIncludeValue,
  isVideo,
} from '../../../../../../../shared/utils';

export const isItemWithAppliedSettings = (
  item: CreatorGalleryFile | GalleryIdea,
): boolean => Object.keys(item).includes('watermark' || 'allowCommenting');

export const onlyVideosWereSelected = (
  files: CreatorGalleryFile[],
  selected: string[],
): boolean => {
  const selectedFiles = files.filter((item) => isArrayIncludeValue(selected, item.id));
  return !selectedFiles.find((item) => !isVideo(item));
};
