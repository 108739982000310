/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios';
import { LeftSpots, Plan, SubscriptionResponse } from '../../modules/Creator/types/payment';
import { ClientSecretKey, ClientPaymentOrder } from '../../shared/types/payment';
import { baseInstance, instance } from '../base/instance';
import { BillingInfo } from '../../shared/types';

export const clientPaymentUrl = 'order/';
export const creatorPaymentUrl = 'subscription/';

export const payment = {
  // creator
  getOnboardingLink: (token: string) => instance.get('creators/onboarding', { headers: { crossDomain: true, Authorization: `${token}` } }),
  subscribeCreator: (priceId: string, token: string): Promise<AxiosResponse<SubscriptionResponse>> => instance.post(`${creatorPaymentUrl}`, { priceId }, { headers: { crossDomain: true, Authorization: `${token}` } }),
  getBillingInfo: (token: string): Promise<AxiosResponse<BillingInfo>> => instance.get(`${creatorPaymentUrl}billing`, { headers: { crossDomain: true, Authorization: `${token}` } }),
  getLeftSpots: (token: string): Promise<AxiosResponse<LeftSpots>> => instance.get(`${creatorPaymentUrl}`, { headers: { crossDomain: true, Authorization: `${token}` } }),
  getPlans: (token: string): Promise<AxiosResponse<Plan[]>> => instance.get(`${creatorPaymentUrl}plans`, { headers: { crossDomain: true, Authorization: `${token}` } }),

  // client
  paymentOrderClient: (data: ClientPaymentOrder): Promise<AxiosResponse<ClientSecretKey>> => baseInstance.post(`${clientPaymentUrl}`, data),
};
