import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { ArrowSimpleIcon } from '../../../../../../../shared/icons';
import { TextBlackLarge } from '../../../../../../../shared/styles';
import { SettingsWrapper } from '../styled';
import * as urls from '../../../../../../../constants/urls';
import { ChangePlanButton } from './styled';
import { PlanCard } from './PlanCard/PlanCard';
import { plans } from '../../../../../../../constants';
import { ChosenPlan, Plan, PlanEnum } from '../../../../../../../shared/types';
import { Summary } from './Summary/Summary';
import { setFirstItemOfList } from '../../../../../../../shared/utils/createGallery';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { SettingsContainer } from '../SettingsContainer';

export const ChangePlanPage: React.FC = () => {
  const { t } = useTranslation('settings');
  const [chosenPlan, setChosenPlan] = useState<ChosenPlan | null>(null);
  const planType = useTypedSelectorCreator(
    (state) => state.creator.currentUser?.currentSubscription?.planName,
  )
    || PlanEnum.Basic;

  const navigate = useNavigate();

  const handleClickToBack = () => {
    navigate(urls.settingsUrl);
  };

  const handleChoosePlan = (plan: ChosenPlan) => {
    setChosenPlan(plan);
  };

  const plansList = useMemo(() => {
    const indexChosenPlan = plans.findIndex(
      (item) => item.planType === chosenPlan?.planType,
    );
    const newPlans = [...plans];
    if (indexChosenPlan >= 0) {
      return setFirstItemOfList<Plan>(newPlans, indexChosenPlan);
    }
    return plans;
  }, [chosenPlan]);

  return (
    <SettingsContainer>
      <SettingsWrapper>
        <ChangePlanButton type="button" onClick={handleClickToBack}>
          <TextBlackLarge>
            <ArrowSimpleIcon color="black" direction="left" />
            <span style={{ marginLeft: '16px' }}>{t('changePlan')}</span>
          </TextBlackLarge>
        </ChangePlanButton>
        <Grid container>
          <Grid container direction="row" item sm={7}>
            {plansList.map((item) => (
              <PlanCard
                key={item.planType}
                handleChoosePlan={handleChoosePlan}
                chosen={item.planType === chosenPlan?.planType}
                planData={item}
                isCurrent={item.planType === planType}
              />
            ))}
          </Grid>
          <Grid container direction="row" item sm={4}>
            {chosenPlan && <Summary plan={chosenPlan} />}
          </Grid>
        </Grid>
      </SettingsWrapper>
    </SettingsContainer>
  );
};
