import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '@clerk/clerk-react';
import { BaseModalWindow } from '../../../../../../../shared/components/BaseModalWindow/BaseModalWindow';
import { SalesCard } from './SalesCard';
import { GalleryInfo } from './GalleryInfo';
import { GalleryTabsDetails } from './GalleryTabsDetails/GalleryTabsDetails';
import { DashboardHeader } from './DashboardHeader/DashboardHeader';
import { WhiteButtonWithoutShadow } from '../../../../../../../shared/styles';
import { DashboardContainer, GalleryInfoWrapper } from './styled';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { fetchGetCreatorInteractions } from '../../../../../redux/gallery/interactions/interactionsCreatorThunk';
import { fetchGetGallerySales } from '../../../../../redux/sales/sales/salesThunk';
import { CreatorGalleryGet } from '../../../../../../../shared/types/creatorGallery';

type DashboardProps = {
  gallery: CreatorGalleryGet;
};

export const Dashboard: React.FC<DashboardProps> = ({ gallery }) => {
  const { t } = useTranslation('activeGalleries');
  const [isOpenDashboard, setIsOpenDashboard] = useState(false);
  const { views, downloads, favourites } = useTypedSelectorCreator((state) => state.interactions);
  const { amount, amountPerWeek } = useTypedSelectorCreator((state) => state.sales?.sales);
  const { getToken } = useAuth();

  const dispatch = useCreatorAppDispatch();

  useEffect(() => {
    if (isOpenDashboard) {
      dispatch(fetchGetCreatorInteractions({ galleryId: gallery?.id, getToken }));
      dispatch(fetchGetGallerySales({ galleryId: gallery?.id, getToken }));
    }
  }, [gallery, isOpenDashboard]);

  const {
    id, name, galleryUrl, password, status,
  } = gallery;

  const ideas = useMemo(
    () => gallery?.sections?.map((section) => section.ideas).flat(),
    [gallery],
  );

  const shares = 0;

  const handleCloseDashboard = () => {
    setIsOpenDashboard(false);
  };

  const handleOpenDashboard = () => {
    setIsOpenDashboard(true);
  };

  return (
    <>
      <WhiteButtonWithoutShadow type="button" onClick={handleOpenDashboard}>
        {t('enterDashboard')}
      </WhiteButtonWithoutShadow>
      <BaseModalWindow
        isOpen={isOpenDashboard}
        handleClose={handleCloseDashboard}
        fitContent
        maxWidth="md"
        transparentBackground
      >
        <DashboardContainer>
          <DashboardHeader
            galleryLink={galleryUrl}
            title={name}
            idGallery={id}
            handleCloseDashboard={handleCloseDashboard}
          />
          <GalleryInfoWrapper>
            <SalesCard sales={amount} salesPerWeek={amountPerWeek} />
            <GalleryInfo
              galleryStatus={status}
              galleryId={id}
              password={password}
              likes={favourites?.length}
              shares={shares}
              views={views.count}
              downloads={downloads?.length}
            />
          </GalleryInfoWrapper>
          <GalleryTabsDetails
            registeredEmails={views.viewers}
            galleryId={id}
            likes={favourites}
            downloads={downloads}
            ideas={ideas}
          />
        </DashboardContainer>
      </BaseModalWindow>
    </>
  );
};
