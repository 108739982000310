import React, { MutableRefObject } from 'react';
import { isImage } from '../../utils';
import { ImageElement } from '../Files/ImageContainer/styled';
import { VideoContainer } from '../Files/VideoContainer/VideoContainer';
import { ClientGalleryFile, CreatorGalleryFile } from '../../types/commonGallery';

type FileForMasonryProps = {
  videoRef?: MutableRefObject<HTMLVideoElement | null>;
  currentFile: ClientGalleryFile | CreatorGalleryFile | null;
  currentFileUrl: string;
  fileId: string;
  fileName: string;
}

export const FileForMasonry: React.FC<FileForMasonryProps> = ({
  fileId,
  fileName,
  videoRef,
  currentFile,
  currentFileUrl,
}) => (
  isImage(currentFile) ? (
    <ImageElement
      hasNoUrl={!currentFileUrl}
      id={fileId}
      src={currentFileUrl}
      data-src={currentFileUrl}
      alt={fileName}
    />
  ) : (
    <VideoContainer
      videoThumbnail={currentFile?.videoThumbnailFile?.url}
      videoUrl={currentFileUrl}
      fileRef={videoRef}
    />
  )
);
