import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { FormGalleryWrapper } from '../styled';
import { SectionThirdStep } from './SectionThirdStep';
import { resetUploadingFiles, resetErrorFiles, unsetError } from '../../../../../redux/gallery/uploadFiles/uploadFilesSliceV2';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';
import { CreatorGalleryForm } from '../../../../../../../shared/types/creatorGallery';

export const NewGalleryCreatorThirdStep: React.FC = () => {
  const { values } = useFormikContext<CreatorGalleryForm>();
  const dispatch = useCreatorAppDispatch();

  useEffect(() => () => {
    dispatch(resetUploadingFiles());
    dispatch(resetErrorFiles());
    dispatch(unsetError());
  }, []);

  return (
    <FormGalleryWrapper>
      {values?.sections?.map((item) => (
        <SectionThirdStep key={item.id} section={item} />
      ))}
    </FormGalleryWrapper>
  );
};
