import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  ActionButton,
  SectionActionsWrapper,
  SectionFilesActionsWrapper,
  SectionsActionsWithErrorWrapper,
} from '../styled';
import { CommonCheckbox } from '../../../../../../../../shared/components/FormElements/CommonCheckbox/CommonCheckbox';
import { SimpleGreyText } from '../../../../../../../../shared/styles';
import { CloseIcon } from '../../../../../../../../shared/icons';
import { CreatorGallerySection } from '../../../../../../../../shared/types/creatorGallery';
import { GalleryIdea } from '../../../../../../../../shared/types/commonGallery';

type SectionActionsProps = {
  section: CreatorGallerySection;
  resetForm: () => void;
  handleSelectAll: (totalCount: number, arrChosen: string[]) => void;
  removeAllSelected: () => void;
  numberOfSelected: number;
  settingsApplied: boolean;
  isLoading: boolean;
};

export const SectionActions: React.FC<SectionActionsProps> = ({
  section,
  handleSelectAll,
  resetForm,
  numberOfSelected,
  removeAllSelected,
  settingsApplied,
  isLoading,
}) => {
  const { t } = useTranslation('createNewGallery');

  const ideas = section.ideas as GalleryIdea[];
  const numberFiles = (section?.files?.length || 0) + (ideas?.length || 0);

  const handleCustomSelectAll = () => {
    const totalNumberOfFiles = (section?.files?.length || 0) + (ideas?.length || 0);
    const arrOfIdeasFiles = [
      ...(section?.files?.map((item) => item.id) || []),
      ...(ideas?.map((item) => item.id) || []),
    ];
    handleSelectAll(totalNumberOfFiles, arrOfIdeasFiles);
  };

  const handleUncheckedChosen = () => {
    removeAllSelected();
    resetForm();
  };

  return (
    <SectionsActionsWithErrorWrapper>
      <SectionActionsWrapper>
        <SectionFilesActionsWrapper>
          <CommonCheckbox
            isChecked={numberOfSelected === numberFiles}
            handleChange={handleCustomSelectAll}
            simple
            name=""
            withoutMargins
            label={(
              <SimpleGreyText>
                {t('thirdStep.all')}
                {' '}
                {numberOfSelected ? ` (${numberOfSelected})` : ''}
              </SimpleGreyText>
              )}
          />
          {(settingsApplied || numberOfSelected > 0) && (
            <ActionButton
              type="button"
              onClick={handleUncheckedChosen}
              disabled={isLoading}
            >
              <Trans t={t} i18nKey="thirdStep.chosenFiles">
                Chosen:
                {' '}
                {{ numberChosenFiles: numberOfSelected }}
                {' '}
                files
              </Trans>
              <CloseIcon />
            </ActionButton>
          )}
        </SectionFilesActionsWrapper>
      </SectionActionsWrapper>
    </SectionsActionsWithErrorWrapper>
  );
};
