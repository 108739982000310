import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { CartReq } from '../../../../shared/types/client';
import { cart } from '../../../../api/client/cart/cart';
import { cartHandle as cartHandleClient } from '../signIn/signInClientSlice';
import { cartHandle } from '../../../GalleryView/redux/gallery/gallerySlice';
import { DownloadsPut } from '../../../../shared/types/interactions';
import { creatorDownloads } from '../../../../api/downloads/downloads';

export const fetchGetClientCart = createAsyncThunk(
  'cartClient/fetchGetClientCart',
  async (galleryId: string, thunkApi) => {
    try {
      const response = await cart.getCart(galleryId);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchAddToCart = createAsyncThunk(
  'cartClient/fetchAddToCart',
  async (data: CartReq, thunkApi) => {
    try {
      const response = await cart.addToCart(data);
      thunkApi.dispatch(cartHandleClient(data.cartItems));
      thunkApi.dispatch(cartHandle(data.cartItems));
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);

export const fetchRemoveFromCart = createAsyncThunk(
  'cartClient/fetchRemoveFromCart',
  async (data: CartReq, thunkApi) => {
    try {
      const response = await cart.removeCartItem(data);
      thunkApi.dispatch(cartHandleClient(data.cartItems));
      thunkApi.dispatch(cartHandle(data.cartItems));
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const fetchAddDownloads = createAsyncThunk(
  'cartClient/fetchAddDownloads',
  async (data: DownloadsPut & { getToken: GetToken }, thunkApi) => {
    try {
      const token = (await data.getToken()) || '';
      const response = await creatorDownloads.addDownloads(
        { galleryId: data.galleryId, filesIds: data.filesIds },
        token,
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue('Error');
    }
  },
);
