import React, { useContext, useMemo, useState } from 'react';
import { Fade } from '@mui/material';
import { InvisibleButton } from './styled';
import { openSwiper } from '../../../../../../../redux/gallery/swiperSlice';
import { getAllFiles } from '../../../../../../../../../shared/utils/gallery/getAllFiles';
import { useGalleryAppDispatch, useTypedSelectorGallery } from '../../../../../../../../../shared/hooks/useTypedSelector';
import { ButtonWrapper, PlayOrPauseButton } from '../../../../../../../../../shared/components/Files/VideoContainer/styled';
import { PlayFilledIcon, PlayIcon } from '../../../../../../../../../shared/icons';
import { isVideo } from '../../../../../../../../../shared/utils';
import { ClientGalleryFile, GalleryIdea } from '../../../../../../../../../shared/types/commonGallery';
import { ClientStateContext } from '../../../../../../../../Client/context/ClientStateContext';

type InvisibleOpenFileButtonProps = {
  file: ClientGalleryFile | GalleryIdea;
  handleMouseOver?: () => void;
  handleMouseOut?: () => void;
};

export const InvisibleOpenFileButton: React.FC<
  InvisibleOpenFileButtonProps
> = ({
  file, handleMouseOut, handleMouseOver,
}) => {
  const gallery = useTypedSelectorGallery((state) => state.gallery.gallery);
  const files = useMemo(() => (gallery ? getAllFiles(gallery) : []), [gallery]);
  const [isPlay, setIsPlay] = useState(false);
  const { isClientSignIn } = useContext(ClientStateContext);

  const dispatch = useGalleryAppDispatch();

  const handleClick = () => {
    dispatch(openSwiper({ files, currentFile: file }));
  };

  const onMouseOver = () => {
    if (handleMouseOver && isVideo(file as any)) {
      handleMouseOver();
      setIsPlay(true);
    }
  };

  const onMouseOut = () => {
    if (handleMouseOut && isVideo(file as any)) {
      handleMouseOut();
      setIsPlay(false);
    }
  };

  return (
    <>
      {isClientSignIn && (
        <InvisibleButton
          type="button"
          onClick={handleClick}
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
        />
      )}
      {isVideo(file as any) ? (
        <ButtonWrapper
          isPlay={isPlay}
        >
          <PlayOrPauseButton
            id="play-button"
            little={false}
            type="button"
            style={{ display: 'flex !important' }}
          >
            {isPlay && <Fade in={isPlay} timeout={400}><div><PlayFilledIcon /></div></Fade>}
            {!isPlay && <Fade in={!isPlay} timeout={400}><div><PlayIcon /></div></Fade>}
            {/* {isPlay ? <PauseIcon /> : <PlayIcon />} */}
          </PlayOrPauseButton>
        </ButtonWrapper>
      ) : null}
    </>
  );
};
