import React, { useMemo } from 'react';
import {
  SectionListWrapper,
} from '../../styled';
import { isArrayEmpty } from '../../../../../../shared/utils';
import { getImagesVideosDocuments } from '../../../../../../shared/utils/gallery/getImagesVideosDocuments';
import { getImagesAndVideosFiltered } from '../../../../../../shared/utils/gallery/getImagesAndVideosFiltered';
import { useGalleryFilters } from '../../../../hooks/useGalleryFilters';
import { DocumentsList } from './DocumentsList/DocumentsList';
import { SectionItemHeader } from './SectionItemHeader';
import { SectionNavigationSelect } from './NavigationSelect/SectionNavigationSelect';
import { SectionItemItemsList } from './styled';
import { ImagesAndVideosList } from './ImagesAndVideosList/ImagesAndVideosList';
import { ClientGallerySection } from '../../../../../../shared/types/clientGallery';

type SectionItemProps = {
  section: ClientGallerySection;
  sections: ClientGallerySection[];
};

export const SectionItem: React.FC<SectionItemProps> = ({
  section,
  sections,
}) => {
  const { chosenFilters, handleChooseFilter } = useGalleryFilters();

  const { arrImagesAndVideos, arrDocuments } = useMemo(
    () => getImagesVideosDocuments(section?.files),
    [section.files],
  );

  const arrIdeas = useMemo(
    () => (isArrayEmpty(chosenFilters) || chosenFilters.includes('ideas')
      ? section.ideas
      : []),
    [chosenFilters, section.ideas],
  );

  const imagesAndVideosFiltered = useMemo(
    () => getImagesAndVideosFiltered(chosenFilters, arrImagesAndVideos),
    [chosenFilters, arrImagesAndVideos],
  );

  return (
    <SectionListWrapper id={section.id}>
      <SectionNavigationSelect currentSection={section} sections={sections} />
      <SectionItemHeader
        section={section}
        arrImagesAndVideos={arrImagesAndVideos}
        arrDocuments={arrDocuments}
        handleChooseFilter={handleChooseFilter}
      />
      <SectionItemItemsList>
        <ImagesAndVideosList
          files={imagesAndVideosFiltered}
          ideas={arrIdeas}
          sectionId={section.id}
        />
        <DocumentsList
          chosenFilters={chosenFilters}
          arrDocuments={arrDocuments}
        />
      </SectionItemItemsList>
    </SectionListWrapper>
  );
};
