import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetToken } from '@clerk/types';
import { gallery } from '../../../../api/gallery/gallery';
import { attachGalleryFilesUrls } from '../../../../shared/utils/gallery/setFileUrls';
import { CreatorGalleryGet, CreatorGalleryPut } from '../../../../shared/types/creatorGallery';

export const fetchGetCreatorGallery = createAsyncThunk(
  'galleryCreator/fetchGetCreatorGallery',
  async ({ id, getToken }: { id: string, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await getToken()) || '';
      const response = await gallery.getGallery(id, token);
      const resGallery = attachGalleryFilesUrls<CreatorGalleryGet>(response.data);
      return resGallery;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response?.data?.message || error.message,
      );
    }
  },
);

export const fetchGetCreatorGalleries = createAsyncThunk(
  'galleryCreator/fetchGetCreatorGalleries',
  async (getToken: GetToken, thunkApi) => {
    try {
      const token = (await getToken()) || '';
      const response = await gallery.getGalleries(token);
      // eslint-disable-next-line max-len
      const resGalleries = response.data.map((item) => attachGalleryFilesUrls<CreatorGalleryGet>(item));
      return resGalleries;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response?.data?.message || error.message,
      );
    }
  },
);

export const fetchDeleteCreatorGallery = createAsyncThunk(
  'galleryCreator/fetchDeleteCreatorGallery',
  async ({ id, getToken }: { id: string, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await getToken()) || '';
      const response = await gallery.deleteGallery(id, token);
      return id;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response?.data?.message || error.message,
      );
    }
  },
);

export const fetchUpdateCreatorGallery = createAsyncThunk(
  'galleryCreator/fetchUpdateCreatorGallery',
  async (data: { id: string; gallery: CreatorGalleryPut, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await data.getToken()) || '';
      const response = await gallery.updateGallery(data.id, data.gallery, token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response?.data?.message || error.message,
      );
    }
  },
);

export const fetchSaveCreatorGallery = createAsyncThunk(
  'galleryCreator/fetchSaveCreatorGallery',
  async (data: { id: string; gallery: CreatorGalleryPut, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await data.getToken()) || '';
      const response = await gallery.updateGallery(data.id, data.gallery, token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response?.data?.message || error.message,
      );
    }
  },
);

export const fetchCreateCreatorGallery = createAsyncThunk(
  'galleryCreator/fetchCreateCreatorGallery',
  async ({ id, getToken }: { id: string, getToken: GetToken }, thunkApi) => {
    try {
      const token = (await getToken()) || '';
      const response = await gallery.createGallery({ id }, token);
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response?.data?.message || error.message,
      );
    }
  },
);

export const deleteGallerySection = createAsyncThunk(
  'galleryCreator/deleteGallerySection',
  async (
    data: { galleryId: string; sectionId: string; getToken: GetToken },
    thunkApi,
  ) => {
    try {
      const token = (await data.getToken()) || '';
      const response = await gallery.deleteSection(
        { galleryId: data.galleryId, sectionId: data.sectionId },
        token,
      );
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response?.data?.message || error.message,
      );
    }
  },
);

export const deleteGalleryFile = createAsyncThunk(
  'galleryCreator/deleteGalleryFile',
  async (
    data: {
      galleryId: string;
      sectionId: string;
      fileId: string;
      getToken: GetToken;
    },
    thunkApi,
  ) => {
    try {
      const token = (await data.getToken()) || '';
      const response = await gallery.deleteFile(
        {
          galleryId: data.galleryId,
          sectionId: data.sectionId,
          fileId: data.fileId,
        },
        token,
      );
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response?.data?.message || error.message,
      );
    }
  },
);
