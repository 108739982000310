import { Grid } from '@material-ui/core';
import React, { ReactElement, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/styles';
import { SuccessNotification } from '../../../../../../../../shared/components/SuccessNotification/SuccessNotification';
import {
  SimpleBlackText,
  MediumBlackText,
  TextPinkLarge,
  TextBlackLarge,
} from '../../../../../../../../shared/styles';
import {
  CurrencyTypes,
  PlanEnum,
} from '../../../../../../../../shared/types';
import { RowsWrapper, TitleMgB } from '../../styled';
import {
  CardInfoWrapper,
  DotsIconElement,
  DotsMenuButton,
  DotsMenuContainer,
  PaymentBillingInfoWrapper,
  PaymentsConteiner,
  PriceWrapper,
} from './styled';
import { PlanCardContainer } from '../../ChangePlanPage/PlanCard/styled';
import {
  SettingsPlanCardContainer,
  SettingsPlanCardContent,
  SettingsPlanCardFooter,
} from '../styled';
import { useTypedSelectorCreator } from '../../../../../../../../shared/hooks/useTypedSelector';
import { getPlanTitle } from '../../../../../../../../shared/utils/payment/getPlanTitle';
import { CommonPriceInputReadOnly } from '../../../../../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import { SubscriptionInfo } from './SubscriptionInfo';
import { UpdatePaymentMethodButton } from './UpdatePaymentMethodButton';
import { getDayMonthYear, getDateFromSec } from '../../../../../../../../shared/utils';

export const CustomMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    display: 'flex',
    flexDirection: 'column',
    background: '#f8f8f8',
  },
  '& .MuiList-padding': {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const DotsIcon: React.FC = () => (
  <DotsIconElement>
    <li />
    <li />
    <li />
  </DotsIconElement>
);

export const CardIcon: React.FC = () => (
  <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
    <title />
    <g id="Credit_card">
      <path d="M454.7734,88.75H57.2227A29.2568,29.2568,0,0,0,28,117.9766V394.0234A29.2568,29.2568,0,0,0,57.2227,423.25H454.7734A29.2576,29.2576,0,0,0,484,394.0234V117.9766A29.2576,29.2576,0,0,0,454.7734,88.75Zm-397.5507,16H454.7734A13.2393,13.2393,0,0,1,468,117.9766V152.75H44V117.9766A13.2385,13.2385,0,0,1,57.2227,104.75ZM468,216.75H44v-48H468Zm-13.2266,190.5H57.2227A13.2385,13.2385,0,0,1,44,394.0234V232.75H468V394.0234A13.2393,13.2393,0,0,1,454.7734,407.25Z" />
      <path d="M117.082,327.25H87.9531a8,8,0,0,0,0,16H117.082a8,8,0,0,0,0-16Z" />
      <path d="M194.52,327.25H165.3906a8,8,0,0,0,0,16H194.52a8,8,0,0,0,0-16Z" />
      <path d="M271.9531,327.25H242.8242a8,8,0,0,0,0,16h29.1289a8,8,0,0,0,0-16Z" />
      <path d="M396.93,295.25a39.59,39.59,0,0,0-22.9863,7.3125,40.0008,40.0008,0,1,0-.0015,65.375A39.6024,39.6024,0,0,0,396.93,375.25a40,40,0,0,0,0-80Zm-69.9063,40a24,24,0,1,1,24,24A24.0263,24.0263,0,0,1,327.0234,335.25Zm69.9063,24a23.7394,23.7394,0,0,1-11.8745-3.2578,39.0277,39.0277,0,0,0,.001-41.4824,23.7309,23.7309,0,0,1,11.8735-3.26,24,24,0,0,1,0,48Z" />
    </g>
  </svg>
);

type DotsMenu = {
  options: ReactElement[];
};

export const DotsMenu: React.FC<DotsMenu> = ({ options }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        style={{
          borderRadius: '50%',
          padding: '10px',
        }}
      >
        <DotsIcon />
      </IconButton>
      <CustomMenu
        id="long-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {options.map((option, index) => (
          <MenuItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            onClick={handleClose}
          >
            {option}
          </MenuItem>
        ))}
      </CustomMenu>
    </div>
  );
};

export const Payments: React.FC = () => {
  const { t } = useTranslation('settings');
  const [isNotificationOpened, setIsNotificationOpened] = useState(false);
  const [isSubscriptionInfoOpen, setIsSubscriptionInfoOpen] = useState(false);

  const { last4 } = useTypedSelectorCreator(
    (state) => state.billingInfo.billingInfo,
  );
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );

  const planTitle = useMemo(
    () => getPlanTitle(
      currentUser?.currentSubscription?.planName || PlanEnum.Basic,
    ),
    [currentUser?.currentSubscription?.planName],
  );

  const dateStart = getDayMonthYear(getDateFromSec(currentUser?.currentSubscription?.subscriptionStart), currentUser?.timezone || '', 'd m y');
  const dateEnd = getDayMonthYear(getDateFromSec(currentUser?.currentSubscription?.subscriptionEnd), currentUser?.timezone || '', 'd m y');

  const downloadInvoice = async () => {
    const date = getDateFromSec(currentUser?.currentSubscription?.subscriptionStart);
    if (currentUser?.currentSubscription.invoiceUrl) {
      const link = document.createElement('a');
      link.href = currentUser?.currentSubscription.invoiceUrl;
      link.setAttribute('download', `invoice-${date}.pdf`);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  };

  return (
    <PaymentsConteiner>
      <TitleMgB>
        <SimpleBlackText>{t('payments')}</SimpleBlackText>
      </TitleMgB>
      <RowsWrapper>
        <Grid container item sm={12} style={{ height: 'fit-content' }}>
          <Grid item xl={8} lg={10} md={12} sm={12}>
            <PlanCardContainer>
              <SettingsPlanCardContainer border="free">
                <SettingsPlanCardContent style={{ position: 'relative' }}>
                  {currentUser?.currentSubscription?.planName !== 'free' && dateStart && dateEnd && (
                  <MediumBlackText>
                    {dateStart}
                    {' '}
                    -
                    {' '}
                    {dateEnd}
                  </MediumBlackText>
                  )}
                  <TextPinkLarge
                    fontWeight={600}
                    style={{ textTransform: 'uppercase' }}
                  >
                    <span>{planTitle}</span>
                  </TextPinkLarge>
                  <SettingsPlanCardFooter style={{ flexDirection: 'column' }}>
                    <PaymentBillingInfoWrapper style={{ marginBottom: '10px' }}>
                      <TextBlackLarge fontWeight={600}>
                        {t('totalBilled')}
                      </TextBlackLarge>
                      <TextBlackLarge fontWeight={600}>
                        <PriceWrapper>
                          <CommonPriceInputReadOnly
                            maxWidth={100}
                            textAlign="right"
                            value={currentUser?.currentSubscription.priceAmount || 0}
                            currenyType={CurrencyTypes.USD}
                          />
                        </PriceWrapper>
                      </TextBlackLarge>
                    </PaymentBillingInfoWrapper>
                    <PaymentBillingInfoWrapper>
                      <CardInfoWrapper>
                        <CardIcon />
                        <MediumBlackText>
                          {' '}
                          {last4 ? (
                            <Trans i18nKey="creaditCardEnd" t={t}>
                              credit card ending in
                              {' '}
                              {{ creaditCardEnding: last4 }}
                            </Trans>
                          ) : 'No attached card'}
                        </MediumBlackText>
                      </CardInfoWrapper>
                      <UpdatePaymentMethodButton />
                      {/* <PaymentSetupWindow /> */}
                    </PaymentBillingInfoWrapper>
                  </SettingsPlanCardFooter>
                  <DotsMenuContainer>
                    <DotsMenu
                      options={[
                        <DotsMenuButton
                          type="button"
                          onClick={downloadInvoice}
                        >
                          {t('downloadInvoice')}
                        </DotsMenuButton>,
                        <DotsMenuButton
                          type="button"
                          onClick={() => {
                            setIsSubscriptionInfoOpen(true);
                          }}
                        >
                          {t('subscriptionInfo')}
                        </DotsMenuButton>,
                      ]}
                    />
                  </DotsMenuContainer>
                </SettingsPlanCardContent>
              </SettingsPlanCardContainer>
            </PlanCardContainer>
          </Grid>
        </Grid>
      </RowsWrapper>
      <SubscriptionInfo
        setIsOpen={setIsSubscriptionInfoOpen}
        isOpen={isSubscriptionInfoOpen}
      />
      <SuccessNotification
        isOpen={isNotificationOpened}
        text={t('basicComponents:changesSaved')}
        setIsOpen={setIsNotificationOpened}
        longTime
      />
    </PaymentsConteiner>
  );
};
