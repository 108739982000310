import { useAuth } from '@clerk/clerk-react';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { fetchUpdateCreatorGallery } from '../../../../../redux/gallery/galleryCreatorThunk';
import { CreatorGalleryPut } from '../../../../../../../shared/types/creatorGallery';

type ReturnObj = {
  updateGallery: (id: string, gallery: CreatorGalleryPut) => void;
  isSuccess: boolean;
  isLoading: boolean;
  error: string;
};

export const useUpdateGallery = (): ReturnObj => {
  const dispatch = useCreatorAppDispatch();
  const isSuccess = useTypedSelectorCreator((state) => state.gallery.isSuccessUpdate);
  const isLoading = useTypedSelectorCreator((state) => state.gallery.loadingUpdate);
  const error = useTypedSelectorCreator((state) => state.gallery.errorUpdate);
  const { getToken } = useAuth();

  const updateGallery = async (id: string, gallery: CreatorGalleryPut) => {
    dispatch(fetchUpdateCreatorGallery({ id, gallery, getToken }));
  };

  return ({
    isLoading,
    error,
    isSuccess,
    updateGallery,
  });
};
