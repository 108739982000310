import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClientSignInReq } from '../../../../shared/types/client';
import { clientSignIn } from '../../../../api/client/clientAuth';
import { getPathData } from '../../utils/helpers';
import { clientGallery } from '../../../../api/client/clientGallery';
import { attachGalleryFilesUrls } from '../../../../shared/utils/gallery/setFileUrls';
import { ClientGalleryGet } from '../../../../shared/types/clientGallery';

export const fetchSignInClient = createAsyncThunk(
  'signInClient/fetchSignInClient',
  async (data: ClientSignInReq, thunkApi) => {
    try {
      const response = await clientSignIn.signIn(data);
      return { response, email: data.email };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  },
);

export const fetchGetClientGallery = createAsyncThunk(
  'signInClient/fetchGetClientGallery',
  async (_, thunkApi) => {
    try {
      const response = await clientGallery.getGallery(getPathData());
      const resGallery = attachGalleryFilesUrls<ClientGalleryGet>(response.data.gallery);
      return { ...response.data, gallery: resGallery };
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  },
);

export const fetchCheckPassGallery = createAsyncThunk(
  'signInClient/fetchCheckPassGallery',
  async (_, thunkApi) => {
    try {
      const response = await clientGallery.checkGalleryLogin(getPathData());
      return response.data;
    } catch (e: any) {
      return thunkApi.rejectWithValue(e.response.data);
    }
  },
);
