import { Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import { useGalleryContent } from '../../../hooks/useGalleryContent';
import {
  SixthImageContainer,
  SixthInfoCardCover,
  SixthMediumTextCenter,
  SixthTitle,
} from './styled';
import { ImageSizes } from '../../../../../constants/galleryPreviewsAndCovers';
import { GalleryCover } from '../../../../../shared/types/commonGallery';

export const GalleryCoverSixth: React.FC<GalleryCover> = (props) => {
  const refCard = useRef<HTMLDivElement>(null);
  const data = useGalleryContent({
    ...props,
    galleryNumber: 'sixth',
  });

  const { withCropper } = props;

  return (
    <Grid
      style={{ marginTop: '20px', marginBottom: '150px' }}
      container
      item
      sm={10}
      spacing={4}
      justifyContent="center"
    >
      <Grid
        style={{
          maxWidth: withCropper ? ImageSizes.sixth.desktop.width : '1280px',
        }}
        container
        item
        sm={12}
        justifyContent="center"
      >
        <SixthImageContainer withCropper={withCropper}>
          {data.file}
          <SixthInfoCardCover
            ref={refCard}
            currentHeight={refCard?.current?.offsetHeight || 0}
          >
            <SixthTitle>{data.name}</SixthTitle>
            <SixthMediumTextCenter>{data.message}</SixthMediumTextCenter>
          </SixthInfoCardCover>
        </SixthImageContainer>
      </Grid>
      <Grid item sm={6}>
        <SixthMediumTextCenter>{data.date}</SixthMediumTextCenter>
        <SixthMediumTextCenter>{data.userName}</SixthMediumTextCenter>
      </Grid>
    </Grid>
  );
};
