import { GetToken } from '@clerk/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { gallery } from '../../../../../api/gallery/gallery';
import { GalleryFileUpdate } from '../../../../../shared/types/commonGallery';

export const fetchUpdateFileSettings = createAsyncThunk(
  'fileSettingsCreator/fetchUpdateFileSettings',
  async (
    data: {
      files: GalleryFileUpdate[];
      getToken: GetToken;
    },
    thunkApi,
  ) => {
    try {
      const token = (await data.getToken()) || '';
      const response = await gallery.fileSettingsUpdate(
        data.files,
        token,
      );
      return response.data;
    } catch (error: any) {
      return thunkApi.rejectWithValue(
        error.response?.data?.message || error.message,
      );
    }
  },
);
