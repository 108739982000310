import React, { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  SubscriptionInfoBtn, SubscriptionInfoContainer, SubscriptionInfoWrapper,
} from './styled';
import { BaseModalWindow } from '../../../../../../../../shared/components/BaseModalWindow/BaseModalWindow';
import { CloseModalWindowButton } from '../../../../../../../../shared/components/CloseModalWindowButton/CloseModalWindowButton';
import {
  SimpleBlackText, SimplePinkText, TextBlackLarge, TextPinkLarge,
} from '../../../../../../../../shared/styles';
import { CommonPriceInputReadOnly } from '../../../../../../../../shared/components/CommonPriceInputReadOnly/CommonPriceInputReadOnly';
import { BillingHistory, CurrencyTypes } from '../../../../../../../../shared/types';
import { useTypedSelectorCreator } from '../../../../../../../../shared/hooks/useTypedSelector';
import { CancelRenewalWindow } from './CancelRenewalWindow';
import { getDayMonthYear, getDateFromSec } from '../../../../../../../../shared/utils';

type SubscriptionInfoProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

export const SubscriptionInfo: React.FC<SubscriptionInfoProps> = ({
  isOpen,
  setIsOpen,
}) => {
  const { t } = useTranslation('settings');
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );
  const currentSubscription = currentUser?.currentSubscription;
  const planType = currentSubscription?.planName || 'free';
  const plan = planType[0].toUpperCase() + planType.slice(1);
  const currency = (currentSubscription?.currency || 'usd').toUpperCase();
  let nextBillingDate = '';
  if (currentSubscription?.subscriptionEnd) {
    nextBillingDate = getDayMonthYear(getDateFromSec(currentSubscription?.subscriptionEnd), currentUser?.timezone || '', 'd m y');
  }
  return (
    <BaseModalWindow
      isOpen={isOpen}
      handleClose={() => setIsOpen(false)}
      fullWidth
      maxWidth="sm"
      transparentBackground
    >
      <SubscriptionInfoContainer>
        <TextBlackLarge style={{ marginBottom: '20px' }}>
          <p>{t('subscriptionInfo')}</p>
        </TextBlackLarge>
        <SubscriptionInfoWrapper>
          <div>
            <TextPinkLarge fontWeight={600}>
              Delivrable
              {' '}
              {plan}
              {' '}
              Membership
            </TextPinkLarge>
            <SimpleBlackText
              fontWeight={500}
              style={{
                display: 'flex', flexDirection: 'row', whiteSpace: 'pre', alignItems: 'center',
              }}
            >
              <div style={{ marginRight: '6px' }}>{currency}</div>
              {' '}
              <CommonPriceInputReadOnly maxWidth={150} textAlign="left" value={currentSubscription?.priceAmount || 0} currenyType={CurrencyTypes.USD} />
            </SimpleBlackText>
          </div>
          <div>
            <TextBlackLarge fontWeight={600}>
              Next bill
            </TextBlackLarge>
            {plan === 'Beta' && (
            <>
              <SimplePinkText style={{ display: 'block' }} fontWeight={500}>
                Essential Membership - 20% OFF
              </SimplePinkText>
              <SimpleBlackText style={{ display: 'block' }}>
                {nextBillingDate}
              </SimpleBlackText>
              <SimpleBlackText>
                US$ 115.20 per year (Usually $144)
              </SimpleBlackText>
            </>
            )}
          </div>
          <SubscriptionInfoBtn>
            <CancelRenewalWindow />
          </SubscriptionInfoBtn>
        </SubscriptionInfoWrapper>
      </SubscriptionInfoContainer>
      <CloseModalWindowButton
        handleClick={() => setIsOpen(false)}
        color="grey"
      />
    </BaseModalWindow>
  );
};
