import React from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from '../../../../../../../shared/icons';
import { useDialog } from '../../../../../../../shared/hooks/useDialog';
import { BasicDocumentCard } from '../../../../../../../shared/components/Files/BasicDocumentCard/BasicDocumentCard';
import { ErrorTryAgain } from './Error/ErrorTryAgain';
import { DocCardHoverElement, DocCardWrapper } from './styled';
import { CreatorGalleryFile } from '../../../../../../../shared/types';

type DocumentCardProps = {
  file: CreatorGalleryFile;
  handleDelete: (name: string) => void;
  handleTryAgain: (fileName: string) => void;
  isLoading?: boolean;
  error?: boolean;
};

export const DocumentCard: React.FC<DocumentCardProps> = ({
  file,
  handleDelete,
  handleTryAgain,
  isLoading,
  error,
}) => {
  const { t } = useTranslation('createNewGallery');

  const { setIsOpen, dialogWindow } = useDialog(
    'confirmation',
    file,
    handleDelete,
    t('secondStep.confirmDelete.question'),
    t('secondStep.confirmDelete.title'),
  );

  const onClickRemove = () => {
    setIsOpen(true);
  };
  const handleClickTryAgain = () => {
    handleTryAgain(file.name);
  };

  return (
    <DocCardWrapper hasError={error} key={file.id}>
      <BasicDocumentCard isLoading={isLoading} file={file} />
      <DocCardHoverElement id="doc-card-hover">
        <ErrorTryAgain
          handleTryAgain={handleClickTryAgain}
          handleDelete={onClickRemove}
          size="sm"
          error={!!error}
          fileName={file?.name || 'No name'}
        />
        <button type="button" onClick={onClickRemove} id="button-hover">
          <DeleteIcon />
        </button>
      </DocCardHoverElement>
      {dialogWindow}
    </DocCardWrapper>
  );
};
