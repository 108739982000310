import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from '../../../../../../../shared/hooks/useDialog';
import { DeleteIcon } from '../../../../../../../shared/icons';
import { FileCardWrapper } from './styled';
import { getFileInContainer } from '../../../../../../../shared/utils/gallery/getFileInContainer';
import { ErrorTryAgain } from './Error/ErrorTryAgain';
import { GalleryFileBaseWithUrls } from '../../../../../../../shared/types/commonGallery';

type FileCardProps = {
  file: GalleryFileBaseWithUrls | null;
  handleDeleteFile: (name: string) => void;
  id: string;
  handleTryAgain: (fileName: string) => void;
  isLoading?: boolean;
  error?: boolean;
  progress?: number;
  isLoadingWithProgress?: boolean;
  errorMessage?: string;
};

export const FileCard: React.FC<FileCardProps> = ({
  file,
  handleDeleteFile,
  id,
  handleTryAgain,
  isLoading,
  error,
  progress,
  isLoadingWithProgress,
  errorMessage,
}) => {
  const { t } = useTranslation('createNewGallery');

  const { setIsOpen, dialogWindow } = useDialog(
    'confirmation',
    error ? file?.name : id,
    handleDeleteFile,
    t('secondStep.confirmDelete.question'),
    t('secondStep.confirmDelete.title'),
  );

  const handleDelete = () => {
    setIsOpen(true);
  };

  const handleClickTryAgain = () => {
    if (file) {
      handleTryAgain(file.name);
    }
  };

  const imageOrVideo = useMemo(
    () => getFileInContainer({
      file, isLoading, progress, isLoadingWithProgress, littlePlayButton: true,
    }),
    [file, isLoading, progress, isLoadingWithProgress],
  );

  return (
    <FileCardWrapper hasError={error} key={file?.id || id}>
      {imageOrVideo}
      <button className="delete-button" type="button" onClick={handleDelete}>
        <DeleteIcon />
      </button>
      {dialogWindow}
      <ErrorTryAgain
        handleTryAgain={handleClickTryAgain}
        handleDelete={handleDelete}
        size="lg"
        error={!!error}
        fileName={file?.name || 'No name'}
        errorMessage={errorMessage}
      />
    </FileCardWrapper>
  );
};
