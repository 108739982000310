import { Grid } from '@material-ui/core';
import React, { useRef } from 'react';
import { useGalleryContent } from '../../../../../../../../GalleryView/hooks/useGalleryContent';
import { GalleryCoversEditor } from '../GalleryCoversEditor';
import { PreviewCard, PreviewWrapper } from '../styled';
import { SelectedPreview } from '../types';
import {
  SixthInfoCardCoverPreview,
  SixthTitlePreview,
  SixthMediumTextPreview,
  ImageContainerSixthPreview,
  SixthMobileWrapper,
} from './styled';
import { GalleryCoverSixth } from '../../../../../../../../GalleryView/components/GalleryCovers/GallerySixth/GalleryCoverSixth';
import { GalleryDemoSixthMobile } from '../../../../../../../../GalleryView/components/GalleryCovers/GallerySixth/GalleryDemoSixthMobile';
import { GalleryDemoSixthTablet } from '../../../../../../../../GalleryView/components/GalleryCovers/GallerySixth/GalleryDemoSixthTablet';
import { TabletWrapper } from '../../../../../../../../GalleryView/components/TabletWrapper';
import { MobileWrapper } from '../../../../../../../../GalleryView/components/MobileWrapper';
import { GalleryCover } from '../../../../../../../../../shared/types/commonGallery';

export const PreviewSixth: React.FC<GalleryCover & SelectedPreview> = ({
  file,
  name,
  date,
  userName,
  message,
  selected,
}) => {
  const refCardPreview = useRef<HTMLDivElement>(null);

  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
  });
  return (
    <PreviewWrapper>
      <PreviewCard theme={selected ? 'selected' : ''}>
        <Grid
          style={{ marginTop: '-10px', marginBottom: '-10px' }}
          container
          justifyContent="center"
        >
          <Grid container item sm={12} justifyContent="center">
            <ImageContainerSixthPreview>
              {data.file}
              <SixthInfoCardCoverPreview
                currentHeight={refCardPreview?.current?.offsetHeight || 0}
                ref={refCardPreview}
              >
                <SixthTitlePreview>{data.name}</SixthTitlePreview>
                <SixthMediumTextPreview>{data.message}</SixthMediumTextPreview>
              </SixthInfoCardCoverPreview>
            </ImageContainerSixthPreview>
          </Grid>
          <Grid item sm={6}>
            <SixthMediumTextPreview>{data.date}</SixthMediumTextPreview>
            <SixthMediumTextPreview>{data.userName}</SixthMediumTextPreview>
          </Grid>
        </Grid>
      </PreviewCard>
      <GalleryCoversEditor>
        <>
          <Grid container justifyContent="center">
            <GalleryCoverSixth
              name={name}
              date={date}
              file={file}
              userName={userName}
              message={message}
              withCropper
              isPreview
            />
          </Grid>
          <Grid container item sm={10} justifyContent="space-between">
            <TabletWrapper>
              <div style={{ paddingLeft: '13px', paddingRight: '13px', height: '100%' }}>
                <GalleryDemoSixthTablet
                  name={name}
                  date={date}
                  file={file}
                  userName={userName}
                  message={message}
                  withCropper
                  isPreview
                />
              </div>
            </TabletWrapper>
            <MobileWrapper>
              <SixthMobileWrapper>
                <GalleryDemoSixthMobile
                  name={name}
                  date={date}
                  file={file}
                  userName={userName}
                  message={message}
                  withCropper
                  isPreview
                />
              </SixthMobileWrapper>
            </MobileWrapper>
          </Grid>
        </>
      </GalleryCoversEditor>
    </PreviewWrapper>
  );
};
