import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonCheckbox } from '../../../../../../../../shared/components/FormElements/CommonCheckbox/CommonCheckbox';
import { useChooseItems } from '../../../../../../../../shared/hooks/useChooseItems';
import { ReminderEmailFormCheckboxes } from './styled';
import { CreatorGalleryForm } from '../../../../../../../../shared/types';

export const ReminderEmailCheckboxesGroup: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const { setFieldValue } = useFormikContext<CreatorGalleryForm>();

  const { handleSelect, isItemSelected, selected } = useChooseItems();
  useEffect(() => {
    setFieldValue('whenRemind', selected);
  }, [selected]);

  return (
    <ReminderEmailFormCheckboxes>
      <div>
        <CommonCheckbox
          isChecked={isItemSelected('oneWeek')}
          name="oneWeek"
          handleChange={handleSelect}
          label={t('fourthStep.reminder.when.oneWeekBefore')}
          size="small"
          id="oneWeek"
          littleText
        />
        <CommonCheckbox
          isChecked={isItemSelected('threeDays')}
          name="threeDays"
          handleChange={handleSelect}
          label={t('fourthStep.reminder.when.threeDaysBefore')}
          size="small"
          id="threeDays"
          littleText
        />
      </div>
      <div>
        <CommonCheckbox
          isChecked={isItemSelected('twoDays')}
          name="twoDays"
          handleChange={handleSelect}
          label={t('fourthStep.reminder.when.twoDaysBefore')}
          size="small"
          id="twoDays"
          littleText
        />
        <CommonCheckbox
          isChecked={isItemSelected('oneDay')}
          name="oneDay"
          handleChange={handleSelect}
          label={t('fourthStep.reminder.when.oneDayBefore')}
          size="small"
          id="oneDay"
          littleText
        />
      </div>
    </ReminderEmailFormCheckboxes>
  );
};
