import React, {
  useContext, useEffect, useMemo,
} from 'react';
import { Grid } from '@material-ui/core';
import { FormikHelpers, useFormik } from 'formik';
import {
  SectionWrapper,
  SectionTitle,
} from '../NewGalleryCreatorSecondStep/styled';
import { FormLimitFreeFiles } from './FormLimitFreeFiles';
import { FilesSection } from './FilesSection';
import { FilesForm } from './FilesForm';
import { SectionActions } from './SectionActions/SectionActions';
import { getCommonValuesOfFileInfo } from '../../../../../../../shared/utils/createGallery/checkCommonValues';
import { galleryFileInformationSchema } from '../../../../../../../shared/validation/gallery/galleryFileInformationSchema';
import { CreatorOnboardingContext } from '../../../../../context/CreatorOnboardingContext';
import { useChooseItems } from '../../../../../../../shared/hooks/useChooseItems';
import { getGalleryFileSettings } from '../../../../../../../constants/initialValues';
import { useApplyFileSettings } from '../hooks/useApplyFileSettings';
import { ErrorMessage } from '../../../../../../../shared/components/styled';
import { DisableDownloadsSwitch } from './DisableDownloadsSwitch';
import { unsetAllValues } from '../../../../../redux/gallery/fileSettings/fileSettingsCreatorSlice';
import { ApplyAndChosenButtons } from './SectionActions/ApplyAndChosenButtons';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';
import { CreatorGallerySection } from '../../../../../../../shared/types/creatorGallery';
import { GalleryFileSettings } from '../../../../../../../shared/types/commonGallery';

type SectionThirdStepProps = {
  section: CreatorGallerySection;
};

export const SectionThirdStep: React.FC<SectionThirdStepProps> = ({
  section,
}) => {
  const { isStudy } = useContext(CreatorOnboardingContext);
  const {
    handleSelectAll,
    handleSelect,
    removeAllSelected,
    isItemSelected,
    numberOfSelected,
    selected,
    setSelected,
  } = useChooseItems();

  const {
    formError,
    handleApplySettings,
    settingsApplied,
    isLoading,
    errorAlert,
    disabled,
  } = useApplyFileSettings({
    isItemSelected,
  });
  const dispatch = useCreatorAppDispatch();

  const initialValues = useMemo(
    () => getGalleryFileSettings(section, selected),
    [section, selected],
  );

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik<GalleryFileSettings>({
    validationSchema: galleryFileInformationSchema,
    initialValues,
    onSubmit: (
      data: GalleryFileSettings,
      bag: FormikHelpers<GalleryFileSettings>,
    ) => {
      handleApplySettings(data, bag, section, selected);
    },
  });

  useEffect(() => {
    if (isStudy) {
      setSelected([section.files[0].id]);
    }
  }, []);

  useEffect(() => {
    setValues(getCommonValuesOfFileInfo(section, selected));
    return () => {
      dispatch(unsetAllValues());
    };
  }, [selected, section, setValues, dispatch]);

  return (
    <SectionWrapper>
      <SectionTitle>{section.title}</SectionTitle>
      {formError ? <ErrorMessage>{formError}</ErrorMessage> : null}
      <Grid container>
        <Grid container item xs={12}>
          <Grid container item xl={5} lg={6} md={9} sm={11} xs={12} style={{ marginTop: '35px' }}>
            <DisableDownloadsSwitch section={section} />
            <FormLimitFreeFiles section={section} />
          </Grid>
          <Grid container item xs={12} style={{ height: 'fit-content' }}>
            <SectionActions
              settingsApplied={settingsApplied}
              isLoading={isLoading}
              resetForm={resetForm}
              section={section}
              handleSelectAll={handleSelectAll}
              removeAllSelected={removeAllSelected}
              numberOfSelected={numberOfSelected}
            />
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid container item lg={8} md={12}>
            <FilesSection
              section={section}
              handleSelect={handleSelect}
              isItemSelected={isItemSelected}
              values={values}
            />
          </Grid>
          <Grid
            container
            item
            lg={4}
            md={12}
            id="filesSettingsThirdStep"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid container item id="filesSettingsThirdStep">
              <FilesForm
                initialValues={initialValues}
                disabledInputs={disabled}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
                selected={selected}
                values={values}
                handleChange={handleChange}
                handleBlur={handleBlur}
                section={section}
              />
              {(settingsApplied || numberOfSelected > 0) && (
              <ApplyAndChosenButtons
                handleSubmit={handleSubmit}
                settingsApplied={settingsApplied}
                isLoading={isLoading}
              />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {errorAlert}
    </SectionWrapper>
  );
};
