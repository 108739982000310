import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  FavouriteCartItem,
  CommentPutRes,
  CartItem,
} from '../../../../shared/types/client';
import { ClientGalleryFile, GalleryIdea } from '../../../../shared/types/commonGallery';
import { ClientGalleryGet } from '../../../../shared/types/clientGallery';

export type SwiperFiles = {
  files: (ClientGalleryFile | GalleryIdea)[];
  currentFile: ClientGalleryFile | GalleryIdea | null;
};

type GalleryState = {
  gallery: ClientGalleryGet | null;
};

const initialState: GalleryState = { gallery: null };

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setGallery(state, action: PayloadAction<ClientGalleryGet>) {
      state.gallery = action.payload;
    },
    likeHandle(state, action: PayloadAction<FavouriteCartItem[]>) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.find((item) => item.fileId === file.id)) {
            file.isLiked = !file.isLiked;
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.find((item) => item.fileId === idea.id)) {
            idea.isLiked = !idea.isLiked;
          }
        });
      });
    },
    cartHandle(state, action: PayloadAction<CartItem[]>) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.find((item) => item.fileId === file.id)) {
            file.isInCart = !file.isInCart;
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.find((item) => item.fileId === idea.id)) {
            idea.isInCart = !idea.isInCart;
          }
        });
      });
    },
    addCommentHandle(state, action: PayloadAction<CommentPutRes>) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.fileId === file.id) {
            file.comments = [
              ...(file.comments || []),
              {
                id: action.payload.messageId,
                text: action.payload.text,
                date: action.payload.date,
                email: action.payload.email,
              },
            ];
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.fileId === idea.id) {
            idea.comments = [
              ...(idea.comments || []),
              {
                id: action.payload.messageId,
                text: action.payload.text,
                date: action.payload.date,
                email: action.payload.email,
              },
            ];
          }
        });
      });
    },
    deleteCommentHandle(
      state,
      action: PayloadAction<{ fileId: string; commentId: string }>,
    ) {
      state.gallery?.sections.forEach((section) => {
        section.files.forEach((file) => {
          if (action.payload.fileId === file.id) {
            file.comments = file.comments?.filter(
              (item) => item.id !== action.payload.commentId,
            );
          }
        });
        section.ideas.forEach((idea) => {
          if (action.payload.fileId === idea.id) {
            idea.comments = idea.comments?.filter(
              (item) => item.id !== action.payload.commentId,
            );
          }
        });
      });
    },
  },
});

export const {
  setGallery,
  likeHandle,
  cartHandle,
  deleteCommentHandle,
  addCommentHandle,
} = gallerySlice.actions;
export default gallerySlice.reducer;
