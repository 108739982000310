import { combineReducers } from '@reduxjs/toolkit';
import notificationsSlice from './notifications/notificationsSlice';
import statisticsSlice from './sales/statistics/statisticsSlice';
import supportSlice from './support/supportSlice';
import gallerySalesSlice from './sales/sales/salesSlice';
import signUpCreatorSlice from './userCreator/signUp/signUpCreatorSlice';
import signInCreatorSlice from './userCreator/signIn/signInCreatorSlice';
import resetPasswordCreatorSlice from './userCreator/resetPassword/resetPasswordCreatorSlice';
import creatorSlice from './userCreator/creator/creatorSlice';
import uploadFilesSlice from './gallery/uploadFiles/uploadFilesSlice';
import swiperSlice from '../../GalleryView/redux/gallery/swiperSlice';
import creatorCheckPasswordSlice from './userCreator/creator/creatorCheckPasswordSlice';
import favouritesCreatorSlice from './gallery/favourites/favouritesCreatorSlice';
import commentsCreatorSlice from './gallery/comments/commentsCreatorSlice';
import galleryCreatorSlice from './gallery/galleryCreatorSlice';
import uploadFilesSliceV2 from './gallery/uploadFiles/uploadFilesSliceV2';
import downloadsCreatorSlice from './gallery/downloads/downloadsCreatorSlice';
import interactionsCreatorSlice from './gallery/interactions/interactionsCreatorSlice';
import allSalesSlice from './sales/sales/allSalesSlice';
import fileSettingsCreatorSlice from './gallery/fileSettings/fileSettingsCreatorSlice';
import billingInfoSlice from './invoices/billingInfoSlice';
import subscriptionSlice from './userCreator/subscriptionSlice';
import plansSlice from './userCreator/plansSlice';

export const rootReducer = combineReducers({
  uploadFiles: uploadFilesSlice,
  notifications: notificationsSlice,
  statistics: statisticsSlice,
  support: supportSlice,
  sales: gallerySalesSlice,
  allSales: allSalesSlice,
  signUpCreator: signUpCreatorSlice,
  signInCreator: signInCreatorSlice,
  resetPassword: resetPasswordCreatorSlice,
  creator: creatorSlice,
  swiper: swiperSlice,
  creatorCheckPassword: creatorCheckPasswordSlice,
  favourites: favouritesCreatorSlice,
  comments: commentsCreatorSlice,
  gallery: galleryCreatorSlice,
  uploadFilesV2: uploadFilesSliceV2,
  downloads: downloadsCreatorSlice,
  interactions: interactionsCreatorSlice,
  fileSettingsCreator: fileSettingsCreatorSlice,
  billingInfo: billingInfoSlice,
  subscription: subscriptionSlice,
  plans: plansSlice,
});
