import React from 'react';
import { Grid } from '@material-ui/core';
import {
  FirstCoverDateTablet,
  FirstMediumTextTablet,
  FirstTitleTablet,
  ImageContainerFirstTablet,
} from './styled';
import { useGalleryContent } from '../../../hooks/useGalleryContent';
import { PreviewFirstHeaderWrapper } from '../../../../Creator/containers/CreatorAccount/Tabs/NewGalleryCreator/NewGalleryCreatorFirstStep/PreviewGalleryCovers/PreviewFirst/styled';
import { GalleryCover } from '../../../../../shared/types/commonGallery';

export const GalleryDemoFirstTablet: React.FC<GalleryCover> = (props) => {
  const { isPreview } = props;
  const data = useGalleryContent({
    ...props,
    size: 'tablet',
    galleryNumber: 'first',
  });

  return (
    <Grid
      container
      spacing={3}
      justifyContent="space-between"
      style={{ padding: '40px 0px 0' }}
    >
      <Grid item sm={12}>
        <PreviewFirstHeaderWrapper>
          <FirstTitleTablet isPreview={!!isPreview}>{data.name}</FirstTitleTablet>
          <FirstMediumTextTablet isPreview={!!isPreview}>{data.userName}</FirstMediumTextTablet>
        </PreviewFirstHeaderWrapper>
      </Grid>
      <Grid item sm={3}>
        <FirstMediumTextTablet isPreview={!!isPreview}>{data.message}</FirstMediumTextTablet>
      </Grid>
      <Grid container item sm={9} justifyContent="flex-end">
        <ImageContainerFirstTablet isPreview={!!isPreview}>
          {data.file}
          <FirstCoverDateTablet isPreview={!!isPreview}>{data.date}</FirstCoverDateTablet>
        </ImageContainerFirstTablet>
      </Grid>
    </Grid>
  );
};
