import { useFormikContext } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { isFirstItemInArr, isLastItemInArr } from '../../../../../../../shared/utils';
import { permuteArrValues } from '../../../../../../../shared/utils/createGallery';
import { ArrowIcon, ArrowSimpleIcon } from '../../../../../../../shared/icons';
import { SectionFooterWrapper, ViewMoreButton } from './styled';
import { CreatorGalleryForm, CreatorGallerySection } from '../../../../../../../shared/types/creatorGallery';

type SectionFooterProps = {
  isExpanded: boolean;
  setIsExpanded: Dispatch<SetStateAction<boolean>>;
  indexSection: number;
  showViewMore: boolean;
};

export const SectionFooter: React.FC<SectionFooterProps> = ({
  isExpanded,
  setIsExpanded,
  indexSection,
  showViewMore,
}) => {
  const { t } = useTranslation('createNewGallery');
  const { values, setFieldValue } = useFormikContext<CreatorGalleryForm>();

  const handleUpSection = () => {
    const newArr = permuteArrValues<CreatorGallerySection>(
      values.sections,
      indexSection,
      'up',
    );
    setFieldValue('sections', newArr);
  };

  const handleDownSection = () => {
    const newArr = permuteArrValues<CreatorGallerySection>(
      values.sections,
      indexSection,
      'down',
    );
    setFieldValue('sections', newArr);
  };

  const isLastSection = isLastItemInArr<CreatorGallerySection>(
    values.sections,
    indexSection,
  );
  const isFirstSection = isFirstItemInArr(indexSection);

  return (
    <SectionFooterWrapper>
      {showViewMore && (
        <ViewMoreButton type="button" onClick={() => setIsExpanded((prev: boolean) => !prev)}>
          <span>{t('secondStep.viewMore')}</span>
          <ArrowSimpleIcon direction={isExpanded ? 'up' : 'down'} />
        </ViewMoreButton>
      )}
      {!isLastSection && (
        <button
          type="button"
          onClick={handleDownSection}
          aria-label="down"
          id={`move${isFirstSection ? 'First' : ''}SectionsSecondStep`}
        >
          <ArrowIcon direction="down" />
        </button>
      )}
      {!isFirstSection && (
        <button type="button" onClick={handleUpSection} aria-label="up">
          <ArrowIcon direction="up" />
        </button>
      )}
    </SectionFooterWrapper>
  );
};
