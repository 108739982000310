import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { fetchUpdateFileSettings } from './fileSettginsCreatorThunk';
import { GalleryFileSettings } from '../../../../../shared/types/commonGallery';

type GalleryFileSettingsSlice = {
  sectionId: string;
  selected: string[];
  settings: GalleryFileSettings;
};

type initialState = {
  loading: boolean;
  error: string;
  data: GalleryFileSettingsSlice[];
  isSuccess: boolean;
};

export const initialState: initialState = {
  loading: false,
  error: '',
  data: [],
  isSuccess: false,
};

const fileSettingsCreatorSlice = createSlice({
  name: 'fileSettingsCreator',
  initialState,
  reducers: {
    setValues(state, action: PayloadAction<GalleryFileSettingsSlice>) {
      const indexSection = state.data.findIndex(
        (item) => item.sectionId === action.payload.sectionId,
      );
      if (indexSection === -1) {
        state.data.push(action.payload);
      } else {
        state.data[indexSection].selected = action.payload.selected;
        state.data[indexSection].settings = action.payload.settings;
      }
    },
    setError(state, action) {
      state.error = action.payload;
    },
    unsetValues(state, action: PayloadAction<{ sectionId: string }>) {
      state.data = state.data.filter(
        (item) => item.sectionId !== action.payload.sectionId,
      );
    },
    unsetAllValues(state) {
      state.data = [];
    },
    unsetIsSuccess(state) {
      state.isSuccess = false;
    },
    unsetError(state) {
      state.error = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchUpdateFileSettings.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loading = false;
        state.isSuccess = false;
      },
    );
    builder.addCase(
      fetchUpdateFileSettings.pending,
      (state, action: PayloadAction<any>) => {
        state.error = '';
        state.loading = true;
        state.isSuccess = false;
      },
    );
    builder.addCase(
      fetchUpdateFileSettings.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.error = '';
        state.loading = false;
        state.isSuccess = true;
      },
    );
  },
});

export const {
  setValues, setError, unsetError, unsetValues, unsetAllValues, unsetIsSuccess,
} = fileSettingsCreatorSlice.actions;

export default fileSettingsCreatorSlice.reducer;
