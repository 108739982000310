import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FavouritesCartResItem, ViewsRes } from '../../../../../shared/types/commonGallery';
import { CommentGet } from '../../../../../shared/types/client';
import {
  fetchDeleteCreatorViews,
  fetchGetCreatorInteractions,
} from './interactionsCreatorThunk';
import { InteractionsGet } from '../../../../../shared/types/interactions';
import { ErrorType } from '../../../../../shared/types';

type initialState = {
  favourites: FavouritesCartResItem[];
  views: ViewsRes;
  downloads: FavouritesCartResItem[];
  comments: CommentGet[];
  loadingGet: boolean;
  loadingDelete: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  favourites: [],
  views: {
    count: 0,
    viewers: [],
  },
  downloads: [],
  comments: [],
  loadingGet: false,
  loadingDelete: false,
  error: null,
};

const interactionsCreatorSlice = createSlice({
  name: 'interactionsCreatorSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetCreatorInteractions.fulfilled,
      (state, action: PayloadAction<InteractionsGet>) => {
        state.error = null;
        state.loadingGet = false;
        state.favourites = action.payload.favourites.files;
        state.views.count = action.payload.views.length;
        state.views.viewers = action.payload.views;
        state.comments = action.payload.comments;
        state.downloads = action.payload.downloads.files;
      },
    );
    builder.addCase(
      fetchGetCreatorInteractions.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchGetCreatorInteractions.pending, (state) => {
      state.loadingGet = true;
    });
    builder.addCase(
      fetchDeleteCreatorViews.fulfilled,
      (state, action: PayloadAction<{ email: string }>) => {
        state.error = null;
        state.loadingDelete = false;
        state.views.count -= 1;
        state.views.viewers = state.views.viewers.filter(
          (item) => item.email !== action.payload.email,
        );
      },
    );
    builder.addCase(
      fetchDeleteCreatorViews.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingDelete = false;
      },
    );
    builder.addCase(fetchDeleteCreatorViews.pending, (state) => {
      state.loadingDelete = true;
    });
  },
});

export default interactionsCreatorSlice.reducer;
