import React, { useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import {
  MediumBlackText, MediumPinkText, TextPinkLarge,
} from '../../../../../../../shared/styles';
import { Plan, PlanEnum } from '../../../../../../../shared/types';
import { getPlanTitle } from '../../../../../../../shared/utils/payment/getPlanTitle';
import {
  PlanCardContainer, DescriptionWrapper,
} from '../ChangePlanPage/PlanCard/styled';
import {
  SettingsPlanCardContainer, SettingsPlanCardContent, SettingsPlanCardFooter,
} from './styled';
import { ListItemWithIcon } from '../../../../../../../shared/components/ListItemWithPinkTick/ListItemWithIcon';
import * as urls from '../../../../../../../constants/urls';
import { useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { getDateFromSec, getDayMonthYear } from '../../../../../../../shared/utils/dateOperations';

type PlanCardProps = {
  planData?: Plan;
};

export const SettingsPlanCard: React.FC<PlanCardProps> = ({
  planData,
}) => {
  const { t } = useTranslation('settings');
  const navigate = useNavigate();

  const planTitle = useMemo(
    () => getPlanTitle(planData?.planType || PlanEnum.Basic),
    [planData?.planType],
  );
  const currentUser = useTypedSelectorCreator(
    (state) => state.creator.currentUser,
  );

  const handleOpenChangePlanPage = () => {
    navigate(`${urls.settingsUrl}${urls.changePlanUrl}`);
  };
  const dateStart = currentUser?.currentSubscription.subscriptionStart;
  // eslint-disable-next-line max-len
  const date = dateStart ? getDateFromSec(dateStart) : null;
  const formattedDate = date ? getDayMonthYear(date, currentUser?.timezone, 'm d y') : '';

  return (
    <Grid item xl={8} lg={10} md={12} sm={12} xs={12} key={planData?.planType}>
      <PlanCardContainer>
        <SettingsPlanCardContainer border={planData?.planType || PlanEnum.Free}>
          <SettingsPlanCardContent>
            <TextPinkLarge fontWeight={600} style={{ textTransform: 'uppercase' }}>
              <span>{planTitle}</span>
            </TextPinkLarge>
            <DescriptionWrapper>
              <MediumBlackText>
                <Trans t={t} i18nKey="subscribedSince">
                  Subscribed since
                  {' '}
                  {{ date: formattedDate }}
                </Trans>
              </MediumBlackText>
            </DescriptionWrapper>
            <div>
              {planData?.details.map((item) => (
                <ListItemWithIcon item={item} />
              ))}
            </div>
            <SettingsPlanCardFooter>
              <MediumPinkText fontWeight={600} style={{ marginRight: '16px' }}>
                {/* hide for beta */}
                {/* Get more storage and 0% commision */}
                Auto upgrades to Essential Plan at official launch
              </MediumPinkText>
              {/* hide for beta */}
              {/* <WhiteButtonFitContent type="button" onClick={handleOpenChangePlanPage}>
                <GradientColorText fontWeight={600}>{t('changePlan')}</GradientColorText>
              </WhiteButtonFitContent> */}
            </SettingsPlanCardFooter>
          </SettingsPlanCardContent>
        </SettingsPlanCardContainer>
      </PlanCardContainer>
    </Grid>
  );
};
