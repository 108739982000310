import { Grid } from '@material-ui/core';
import React from 'react';
import { useGalleryContent } from '../../../../../../../../GalleryView/hooks/useGalleryContent';
import { GalleryCoversEditor } from '../GalleryCoversEditor';
import { PreviewWrapper } from '../styled';
import { SelectedPreview } from '../types';
import {
  PreviewCardFirst,
  PreviewFirstContentWrapper,
  PreviewFirstHeaderWrapper,
  PreviewFirstImageContainer,
  PreviewFirstMediumText,
  PreviewFirstRowWrapper,
  PreviewFirstTitle,
} from './styled';
import { GalleryCoverFirst } from '../../../../../../../../GalleryView/components/GalleryCovers/GalleryFirst/GalleryCoverFirst';
import { GalleryDemoFirstMobile } from '../../../../../../../../GalleryView/components/GalleryCovers/GalleryFirst/GalleryDemoFirstMobile';
import { GalleryDemoFirstTablet } from '../../../../../../../../GalleryView/components/GalleryCovers/GalleryFirst/GalleryDemoFirstTablet';
import { MobileWrapperFirst } from '../../../../../../../../GalleryView/components/GalleryCovers/GalleryFirst/styled';
import { TabletWrapper } from '../../../../../../../../GalleryView/components/TabletWrapper';
import { MobileWrapper } from '../../../../../../../../GalleryView/components/MobileWrapper';
import { GalleryCover } from '../../../../../../../../../shared/types/commonGallery';

export const PreviewFirst: React.FC<GalleryCover & SelectedPreview> = ({
  file,
  name,
  date,
  userName,
  message,
  selected,
}) => {
  const data = useGalleryContent({
    file,
    name,
    date,
    userName,
    message,
  });
  return (
    <PreviewWrapper>
      <PreviewCardFirst theme={selected ? 'selected' : ''} id="galleryCoverDesignFirstStep">
        <PreviewFirstContentWrapper>
          <PreviewFirstHeaderWrapper>
            <PreviewFirstTitle>{data.name}</PreviewFirstTitle>
            <PreviewFirstMediumText>{data.userName}</PreviewFirstMediumText>
          </PreviewFirstHeaderWrapper>
          <PreviewFirstRowWrapper>
            <PreviewFirstMediumText>{data.message}</PreviewFirstMediumText>
            <PreviewFirstImageContainer>
              {data.file}
              {data.date}
            </PreviewFirstImageContainer>
          </PreviewFirstRowWrapper>
        </PreviewFirstContentWrapper>
      </PreviewCardFirst>
      <GalleryCoversEditor>
        <>
          <GalleryCoverFirst
            name={name}
            date={date}
            file={file}
            userName={userName}
            message={message}
            withCropper
            isPreview
          />
          <Grid container item sm={10} justifyContent="space-between">
            <TabletWrapper>
              <div style={{ padding: '73px 13px 0px' }}>
                <GalleryDemoFirstTablet
                  name={name}
                  date={date}
                  file={file}
                  userName={userName}
                  message={message}
                  withCropper
                  isPreview
                />
              </div>
            </TabletWrapper>
            <MobileWrapper>
              <MobileWrapperFirst>
                <GalleryDemoFirstMobile
                  name={name}
                  date={date}
                  file={file}
                  userName={userName}
                  message={message}
                  withCropper
                  isPreview
                />
              </MobileWrapperFirst>
            </MobileWrapper>
          </Grid>
        </>
      </GalleryCoversEditor>
    </PreviewWrapper>
  );
};
