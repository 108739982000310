import React from 'react';
import { Grid } from '@material-ui/core';
import { ImageOrVideoCard } from './ImageOrVideoCard/ImageOrVideoCard';
import { CustomMasonry, CustomImageListItem } from '../../../../../../../shared/styles';
import { useCheckWindowSize } from '../../../../../../../shared/hooks/useCheckWindowSize';
import { ClientGalleryFile, GalleryIdea } from '../../../../../../../shared/types/commonGallery';

type ImagesAndVideosListProps = {
  files: ClientGalleryFile[];
  ideas: GalleryIdea[];
  sectionId: string;
};

export const getArray = (arr: (ClientGalleryFile | GalleryIdea)[]):
(ClientGalleryFile | GalleryIdea | (ClientGalleryFile | GalleryIdea)[])[] => {
  const res: (ClientGalleryFile | GalleryIdea | (ClientGalleryFile | GalleryIdea)[])[] = [];
  let filesNotFullWidth: (ClientGalleryFile | GalleryIdea)[] = [];
  arr.forEach((item) => {
    if (!item.fullWidth) {
      filesNotFullWidth.push(item);
    } else {
      res.push(filesNotFullWidth);
      res.push(item);
      filesNotFullWidth = [];
    }
  });
  res.push(filesNotFullWidth);
  return res;
};

export const ImagesAndVideosList: React.FC<ImagesAndVideosListProps> = ({
  files,
  ideas,
  sectionId,
}) => {
  const { screenSize } = useCheckWindowSize();

  let columns = 3;
  if (screenSize === 'tablet') {
    columns = 4;
  }
  if (screenSize === 'mobile') {
    columns = 2;
  }

  return (
    <Grid container item xs={12}>
      {getArray([...files, ...ideas]).map((arrItem, arrIndex) => (
        Array.isArray(arrItem) ? (
          <CustomMasonry
            spacing={0}
            columns={columns}
          // eslint-disable-next-line react/no-array-index-key
            key={arrIndex}
          >
            {arrItem.map((item, index) => (
              <CustomImageListItem key={item.id}>
                <ImageOrVideoCard
                  key={item.id}
                  fileOrIdea={item}
                  sectionId={sectionId}
                  fileIndex={index}
                />
              </CustomImageListItem>
            ))}
          </CustomMasonry>
        ) : (
          <ImageOrVideoCard
            key={arrItem.id}
            fileOrIdea={arrItem}
            sectionId={sectionId}
            fileIndex={arrIndex}
          />
        )
      ))}
    </Grid>
  );
};
