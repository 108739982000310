/* eslint-disable no-underscore-dangle */
import axios from 'axios';

export const baseURL = `${process.env.REACT_APP_BASE_API_URL}/`;

export const instance = axios.create({
  withCredentials: true,
  headers: { crossDomain: true, 'Content-Type': 'application/json', Role: 'creator' },
  baseURL,
});

export const instanceBeta = axios.create({
  withCredentials: true,
  headers: { crossDomain: true, 'Content-Type': 'application/json' },
  baseURL,
});

export const clientInstance = axios.create({
  withCredentials: true,
  headers: { crossDomain: true, 'Content-Type': 'application/json', Role: 'client' },
  baseURL,
});

export const baseInstance = axios.create({
  baseURL,
  headers: { Role: 'client' },
});

export const getHeader = (headers: Headers) => {
  headers.set('Access-Control-Allow-Origin', '*');
  headers.set('Access-Control-Allow-Methods', 'GET, POST, PUT, PATCH, DELETE, OPTIONS');
  headers.set('Content-Type', 'application/json');
  return headers;
};
