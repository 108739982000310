import React, { Dispatch, SetStateAction, useState } from 'react';
import { useAuth } from '@clerk/clerk-react';
import { baseInstance, instance } from '../../../api/base/instance';

type ReturnObj = {
  isLoading: boolean;
  error: string;
  setError: Dispatch<SetStateAction<string>>;
  clientSecretKey: string;
  getClientSecretKey: (priceId: string) => void;
}

export const useSubscriptionCheckout = (): ReturnObj => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [clientSecretKey, setClientSecretKey] = useState('');
  const { getToken } = useAuth();

  const getClientSecretKey = async (priceId: string) => {
    setIsLoading(true);
    const token = await getToken();
    instance.post('subscription', { priceId }, { headers: { crossDomain: true, Authorization: `${token}` } })
      .then((res) => setClientSecretKey(res.data.clientSecret))
      .catch((err) => { console.log({ err }); setError('Something went wrong. Try it later'); })
      .finally(() => setIsLoading(false));
  };

  return ({
    isLoading,
    error,
    clientSecretKey,
    setError,
    getClientSecretKey,
  });
};
