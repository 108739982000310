import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import {
  SimpleBlackText,
  TextBlackLarge,
} from '../../../../../../shared/styles';
import { GalleryCardLink, HeaderGalleryCard } from './styled';
import { ViewAllLink } from '../../../../../../shared/components/ViewAllLink/ViewAllLink';
import {
  GalleryCardCover,
  GalleryCardWrapper,
} from '../../../../../../shared/components/GalleryCard/styled';
import { getFileInContainer } from '../../../../../../shared/utils/gallery/getFileInContainer';
import { CreatorGalleryGet } from '../../../../../../shared/types/creatorGallery';

type GalleryCardCustomProps = {
  gallery: null | CreatorGalleryGet;
  galleriesNumber: number;
  type: 'Active' | 'Draft';
  toUrl: string;
};

export const GalleryCardCustom: React.FC<GalleryCardCustomProps> = ({
  gallery,
  galleriesNumber = 0,
  type,
  toUrl,
}) => {
  const { t } = useTranslation(['gallery', 'basicComponents']);

  const coverImageUrl = gallery ? gallery.coverFile : null;
  const fileElement = coverImageUrl ? (
    getFileInContainer({
      file: {
        id: gallery?.coverFile?.desktop?.id || gallery?.coverFile?.original?.id || '',
        type: gallery?.coverFile?.desktop?.type || gallery?.coverFile?.original?.type || 'image/jpeg',
        name: gallery?.coverFile?.desktop?.name || (gallery?.coverFile?.original?.name ? gallery?.coverFile?.original?.name ?? 'cover' : 'cover'),
        url: gallery?.coverFile?.desktop?.url || gallery?.coverFile?.original?.url || '',
      },
    })
  ) : (
    <SimpleBlackText>{t('gallery:noCoverPhoto')}</SimpleBlackText>
  );

  return (
    <Grid item xl={6} lg={6} md={12} sm={12}>
      <GalleryCardWrapper>
        <HeaderGalleryCard>
          <TextBlackLarge>
            <Trans t={t} i18nKey="gallery:galleriesWithNumber">
              {{ type }}
              {' '}
              galleries (
              {{ galleries: galleriesNumber }}
              )
            </Trans>
          </TextBlackLarge>
          {galleriesNumber ? (
            <ViewAllLink label={t('basicComponents:viewAll')} to={toUrl} />
          ) : null}
        </HeaderGalleryCard>
        <GalleryCardLink to={toUrl}>
          <GalleryCardCover>
            {galleriesNumber ? (
              fileElement
            ) : (
              <SimpleBlackText>{t('gallery:noGalleries')}</SimpleBlackText>
            )}
          </GalleryCardCover>
        </GalleryCardLink>
      </GalleryCardWrapper>
    </Grid>
  );
};
