import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { BaseCropper } from '../../../shared/components/BaseCropper/BaseCropper';
import { isVideo } from '../../../shared/utils';
import { getInitialCroppedAreaPixels } from '../../../shared/utils/createGallery';
import { CreatorGalleryForm } from '../../../shared/types/creatorGallery';
import { ImageSize } from '../../../shared/types/commonGallery';

type GalleryFileCropperProps = {
  width: number;
  height: number;
  size: ImageSize;
  fileName?: 'coverFile' | 'extraCover1' | 'extraCover2';
};

export const GalleryFileCropper: React.FC<GalleryFileCropperProps> = ({
  width,
  height,
  size,
  fileName = 'coverFile',
}) => {
  const { values } = useFormikContext<CreatorGalleryForm>();

  const fileUrl = values[fileName]?.original?.url;

  const initialCroppedAreaPixels = useMemo(
    () => getInitialCroppedAreaPixels(size, values[fileName]),
    [values[fileName], size],
  );

  const videoOrImage = isVideo(values[fileName]?.original || null)
    ? { video: fileUrl }
    : { image: fileUrl };

  return (
    <BaseCropper
      initialCroppedAreaPixels={initialCroppedAreaPixels}
      size={fileName !== 'coverFile' ? `${fileName}${size}` : size}
      width={width}
      height={height}
      videoOrImage={videoOrImage}
    />
  );
};
