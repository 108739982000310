/* eslint-disable */
import { useFormikContext } from 'formik';
import React, { ChangeEvent, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Trans, useTranslation } from 'react-i18next';
import { IdeaModalWindow } from './Ideas/IdeaModalWindow';
import { ButtonCard, CardBorder, PositionedHelpTip } from './styled';
import IdeaImage from '../../../../../../../assets/image/galleryImages/IdeaImage.png';
import { HelpTip } from '../../../../../../../shared/components/HelpTip/HelpTip';
import { DragAndDropFiles } from './DragAndDropFiles';
import { PinkLink } from '../../../../../../../shared/styles';
import { GalleryId, FileRole } from '../../../../../types/gallery';
import { OnUploadProps } from '../../../../../hooks/useUploadFiles';
import { CreatorGalleryForm, CreatorGallerySection } from '../../../../../../../shared/types/creatorGallery';
import { GalleryFileBaseWithUrls, GalleryIdea } from '../../../../../../../shared/types/commonGallery';

const currentIdeaId = uuidv4();
const newIdea: GalleryIdea = {
  id: currentIdeaId,
  name: '',
  description: '',
  price: '',
  files: [],
  cover: null as unknown as GalleryFileBaseWithUrls,
};

type SectionAddContentProps = {
  section: CreatorGallerySection;
  indexSection: number;
  gallery: string;
  onUpload: (props: OnUploadProps) => void;
};

export const SectionAddContent: React.FC<SectionAddContentProps> = ({
  section,
  indexSection,
  gallery,
  onUpload,
}) => {
  const { t } = useTranslation('createNewGallery');
  const [isOpenIdeaModal, setIsOpenIdeaModal] = useState(false);
  const { setFieldValue, values, setFieldError, setFieldTouched } =
    useFormikContext<CreatorGalleryForm>();

  const { ideas } = section;

  const handleCreateIdea = () => {
    setFieldValue(`sections[${indexSection}].ideas`, [...ideas, newIdea]);
    setIsOpenIdeaModal(true);
  };
  const lastIdea = ideas[ideas?.length - 1];

  const removeCreatedIdea = (id: string) => {
    const remainIdeas = values.sections[indexSection].ideas.filter((item) => item.id !== id);
    setFieldError(`sections[${indexSection}].ideas`, '');
    setFieldTouched(`sections[${indexSection}].ideas`, false);
    setFieldValue(`sections[${indexSection}].ideas`, remainIdeas);
  };

  const helpIdeaText = (
    <Trans t={t} i18nKey="secondStep.helpTexts.ideas">
      See community{' '}
      <PinkLink underline href="/">
        best examples
      </PinkLink>
    </Trans>
  );

  return (
    <>
      <CardBorder id="dragAndDropFilesSecondStep">
        <DragAndDropFiles onUpload={onUpload} indexSection={indexSection} section={section} gallery={gallery} />
      </CardBorder>
      {/* hide for now */}
      {/* <CardBorder id="addIdeaSecondStep">
        <ButtonCard onClick={handleCreateIdea}>
          <PositionedHelpTip id="positioned-help-tip">
            <HelpTip text={helpIdeaText} />
          </PositionedHelpTip>
          <img src={IdeaImage} alt="idea" />
          {t('secondStep.ideas')}
        </ButtonCard>
        <IdeaModalWindow
          removeCreatedIdea={removeCreatedIdea}
          isOpen={isOpenIdeaModal}
          setIsOpen={setIsOpenIdeaModal}
          idea={lastIdea}
          indexSection={indexSection}
          indexIdea={ideas?.length - 1}
        />
      </CardBorder> */}
    </>
  );
};
