import { Grid } from '@material-ui/core';
import React from 'react';
import { SignedIn, SignedOut } from '@clerk/clerk-react';
import { GridFullHeight, GridItemFullHeight } from '../../../shared/components/GlobalContainer/styled';
import { HeaderWithLogo } from '../../../shared/components/HeaderWithLogo/HeaderWithLogo';
import { CreatorSignedInRoutes, CreatorSignedOutRoutes } from '../routers/CreatorRoutes';
import { Sidebar } from './CreatorAccount/Sidebar/Sidebar';
import { CreatorWalkThroughCards } from './CreatorAccount/CreatorWalkThroughCards/CreatorWalkThroughCards';
import { CreatorProfileGet } from '../../../shared/types';
import { BetaCheckoutWindow } from '../../PasswordProtectedPage/containers/BetaCheckout';
import { useTypedSelectorCreator } from '../../../shared/hooks/useTypedSelector';

export const Creator: React.FC = () => {
  const currentUser = useTypedSelectorCreator((state) => state.creator.currentUser);
  return (
    <>
      <SignedOut>
        <HeaderWithLogo isCreator={false} />
        <CreatorSignedOutRoutes />
      </SignedOut>
      <SignedIn>
        <GridFullHeight container spacing={4} justifyContent="space-between">
          <GridItemFullHeight item sm={3} xs={3}>
            <Sidebar />
          </GridItemFullHeight>
          <Grid item xl={8} md={8} style={{ paddingLeft: '32px' }}>
            <CreatorSignedInRoutes />
          </Grid>
        </GridFullHeight>
        {/* {currentUser && <NewsPopupWindow id={currentUser?.id} />} */}
        {(!currentUser?.currentSubscription?.priceId) && <BetaCheckoutWindow />}
        <CreatorWalkThroughCards />
      </SignedIn>
    </>
  );
};
