import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MiniFileCard } from '../../../../../../../../shared/components/GalleryCreator/MiniFileCard';
import { useDialog } from '../../../../../../../../shared/hooks/useDialog';
import { useErrorFiles } from '../../../../../../hooks/useErrorFiles';
import { useLoadingFiles } from '../../../../../../hooks/useLoadingFiles';
import { useSendFilesAgain } from '../../../../../../hooks/useSendAndSetFiles';
import { ErrorTryAgain } from '../Error/ErrorTryAgain';
import { DocCardHoverElement } from '../styled';
import { AttachedFileWrapper } from './styled';
import * as variables from '../../../../../../../../constants/styles';
import { GalleryFileBaseWithUrls } from '../../../../../../../../shared/types/commonGallery';

type AttachedFile = {
  file: GalleryFileBaseWithUrls | null;
  isChecked: boolean;
  handleCheck: (e: ChangeEvent<HTMLInputElement>, file: GalleryFileBaseWithUrls) => void;
  handleDeleteFile: () => void;
  // handleTryAgain: (file: CustomFile[]) => void;
}

const AttachedFile: React.FC<AttachedFile> = ({
  file,
  isChecked,
  handleCheck,
  handleDeleteFile,
  // handleTryAgain,
}) => {
  const { t } = useTranslation('createNewGallery');
  const { sendFilesAgain } = useSendFilesAgain();

  const errorFile = useErrorFiles(file?.id || '');
  const isLoading = useLoadingFiles(file?.id || '');
  // const errorFile = true;

  const { setIsOpen, dialogWindow } = useDialog(
    'confirmation',
    file?.id,
    handleDeleteFile,
    t('secondStep.confirmDelete.question'),
    t('secondStep.confirmDelete.title'),
  );

  const handleDelete = () => {
    setIsOpen(true);
  };

  const handleClickTryAgain = () => {
    if (file) {
      sendFilesAgain([file]);
    }
  };

  return (
    <AttachedFileWrapper>
      <div style={{
        border: errorFile && file?.id ? `1px solid ${variables.colorError}` : 'none',
        borderRadius: '4px',
      }}
      >
        <MiniFileCard
          key={typeof file === 'number' ? file : file?.id}
          file={file}
          handleCheck={handleCheck}
          isChecked={isChecked}
          id={file?.id}
          isLoading={isLoading}
          margins={false}
        />
      </div>
      {dialogWindow}
      {file?.id
      && (
      <DocCardHoverElement id="attached-file-hover">
        <ErrorTryAgain
          handleTryAgain={handleClickTryAgain}
          handleDelete={handleDelete}
          size="sm"
          error={errorFile}
          fileName={file?.name || 'No name'}
        />
      </DocCardHoverElement>
      )}
    </AttachedFileWrapper>
  );
};

type AttachedFilesListProps = {
  arrFiles: (GalleryFileBaseWithUrls | null)[];
  ideaCoverFileId: string;
  handleSetCoverFile: (e: ChangeEvent<HTMLInputElement>, file: GalleryFileBaseWithUrls) => void;
  handleDeleteFile: (id: string) => void;
}

export const AttachedFilesList: React.FC<AttachedFilesListProps> = ({
  arrFiles,
  ideaCoverFileId,
  handleSetCoverFile,
  handleDeleteFile,
}) => {
  const [checkedFile, setCheckedFile] = useState<string | null>(ideaCoverFileId);

  const handleCheck = (e: ChangeEvent<HTMLInputElement>, file: GalleryFileBaseWithUrls) => {
    handleSetCoverFile(e, file);
    setCheckedFile(e.target.id);
  };

  return (
    <>
      {arrFiles.map((item) => (
        <AttachedFile
          file={item}
          handleCheck={handleCheck}
          isChecked={typeof item !== 'number' && checkedFile === item?.id}
          handleDeleteFile={() => handleDeleteFile(item?.id || '')}
          // handleTryAgain={}
        />
      ))}
    </>
  );
};
