import { v4 as uuidv4 } from 'uuid';
import gallery7 from '../../assets/image/walkThroughCards/gallery7.jpg';
import gallery8 from '../../assets/image/walkThroughCards/gallery8.jpg';
import gallery9 from '../../assets/image/walkThroughCards/gallery9.jpg';
import gallery10 from '../../assets/image/walkThroughCards/gallery10.jpg';
import gallery11 from '../../assets/image/walkThroughCards/gallery11.jpg';
import { CreatorGalleryForm, CreatorGalleryGet } from '../../shared/types/creatorGallery';

export const fakeGallery: CreatorGalleryForm = {
  creatorBusinessName: 'john',
  id: 'study',
  status: 'draft',
  removeBranding: false,
  hasWhitelistEmails: false,
  hasReminder: false,
  remindAllRegistered: false,
  whenRemind: [],
  remindEmails: [],
  whitelistRemindEmails: [],
  name: 'Your gallery name',
  galleryUrl: 'timbremediahouse.delivrable.com/yourgalleryname',
  step: 1,
  coverFile: {
    original: {
      url: gallery7,
      type: 'image',
      name: 'gallery7',
      id: uuidv4(),
    },
    mobile: null,
    tablet: null,
    desktop: null,
  },
  date: new Date(),
  message:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy.",
  galleryDesign: 1,
  sections: [
    {
      id: uuidv4(),
      title: 'Section 1',
      files: [
        {
          id: uuidv4(),
          url: gallery7,
          type: 'image',
          name: 'gallery7',
          instantlyDownloadable: true,
          price: '30',
          description: 'Anna',
          allowCommenting: true,
        },
        {
          id: uuidv4(),
          url: gallery8,
          type: 'image',
          name: 'gallery8',
        },
        {
          id: uuidv4(),
          url: gallery9,
          type: 'image',
          name: 'gallery9',
        },
        {
          id: uuidv4(),
          url: gallery10,
          type: 'image',
          name: 'gallery10',
        },
        {
          id: uuidv4(),
          url: gallery11,
          type: 'image',
          name: 'gallery11',
        },
      ],
      ideas: [],
      hasFreeFiles: true,
      numberFreeFiles: 15,
    },
    {
      id: uuidv4(),
      title: 'Section 2',
      files: [
        {
          id: uuidv4(),
          url: gallery7,
          type: 'image',
          name: 'gallery7',
          instantlyDownloadable: true,
          allowCommenting: true,
        },
        {
          id: uuidv4(),
          url: gallery11,
          type: 'image',
          name: 'gallery11',
        },
        {
          id: uuidv4(),
          url: gallery10,
          type: 'image',
          name: 'gallery10',
        },
        {
          id: uuidv4(),
          url: gallery9,
          type: 'image',
          name: 'gallery9',
        },
        {
          id: uuidv4(),
          url: gallery8,
          type: 'image',
          name: 'gallery8',
        },
      ],
      ideas: [],
      hasFreeFiles: false,
      numberFreeFiles: null,
    },
  ],
  hasRegistrationEmail: false,
  whitelistEmails: [],
  password: 'newcontent2021',
  sharing: true,
  assist: true,
  autoAlert: false,
  branding: false,
  testimonialCall: false,
};

export const fakeCreatorGalleries: CreatorGalleryGet[] = [
  {
    id: '1d1d11-safe21',
    name: 'Gallery name 1',
    galleryUrl: 'businessName.delivrable.com/Galleryname1',
    coverFile: {
      original: {
        name: 'cover',
        url: gallery8,
        type: 'image/jpeg',
        id: '',
      },
      desktop: {
        name: 'cover',
        url: gallery8,
        type: 'image/jpeg',
        id: '',
      },
      tablet: {
        name: 'cover',
        type: 'image/jpeg',
        id: '',
      },
      mobile: {
        name: 'cover',
        type: 'image/jpeg',
        id: '',
      },
    },
    status: 'active',
    password: 'passworD123',
    sections: [],
    creatorBusinessName: '',
    message: '',
    galleryDesign: 1,
    hasRegistrationEmail: false,
    hasWhitelistEmails: false,
    sharing: false,
    assist: false,
    autoAlert: false,
    branding: false,
    testimonialCall: false,
    removeBranding: false,
    hasReminder: false,
    remindAllRegistered: false,
    date: String(new Date()),
  },
  {
    id: 'wewq1qe-wq564q',
    name: 'Gallery name 2',
    galleryUrl: 'businessName.delivrable.com/Galleryname2',
    coverFile: {
      original: {
        name: 'cover',
        url: gallery7,
        type: 'image/jpeg',
        id: '',
      },
      desktop: {
        name: 'cover',
        url: gallery7,
        type: 'image/jpeg',
        id: '',
      },
      tablet: {
        name: 'cover',
        type: 'image/jpeg',
        id: '',
      },
      mobile: {
        name: 'cover',
        type: 'image/jpeg',
        id: '',
      },
    },
    status: 'draft',
    password: 'passworD123',
    sections: [],
    creatorBusinessName: '',
    message: '',
    galleryDesign: 1,
    hasRegistrationEmail: false,
    hasWhitelistEmails: false,
    sharing: false,
    assist: false,
    autoAlert: false,
    branding: false,
    testimonialCall: false,
    removeBranding: false,
    hasReminder: false,
    remindAllRegistered: false,
    date: String(new Date()),
  },
];
