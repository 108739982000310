/* eslint-disable prefer-const */
import { Dispatch, SetStateAction } from 'react';
import { CreatorGalleryFile } from '../../../shared/types/commonGallery';

export const getPathData = (): { businessName: string, galleryName: string } => {
  const path = window.location.href;
  const splitedPath = path.split('/');
  const businessName = splitedPath[2].split('.')[0];

  return ({
    businessName,
    galleryName: path?.split('/')[3],
  });
};

export const getAwsKey = (file: CreatorGalleryFile) => {
  if (file.awsKey) {
    return file.awsKey;
  }
  return file.id;
};

const awsDomain = `${process.env.REACT_APP_BASE_MEDIA_URL}/`;

export async function checkArchiveStatus(
  url: string,
  orderId: string,
  setError: Dispatch<SetStateAction<string>>,
  setDownloadLink: Dispatch<SetStateAction<string>>,
  setIsLoading: Dispatch<SetStateAction<boolean>>,
): Promise<any> {
  let cnt = 0;
  let interval: any;
  interval = setInterval(async () => {
    const result = await fetch(`${awsDomain}${orderId}.zip`);
    cnt++;
    if (result.status === 200) {
      clearInterval(interval);
      setIsLoading(false);
      setDownloadLink(`${awsDomain}${orderId}.zip`);
    } else if (cnt >= 100) {
      setIsLoading(false);
      clearTimeout(interval);
      setError('Archive preparing has failed. Try again later, please.');
    }
  }, 3000);
}
