import React, { ChangeEvent } from 'react';
import { ImageList, ImageListProps, styled as MUIstyled } from '@mui/material';
import { MiniFileCard } from '../../../../../shared/components/GalleryCreator/MiniFileCard';
import { isDocument } from '../../../../../shared/utils';
import { FileModalWindow } from '../../GalleryClientView/FileModalWindow';
import { FavouritesListItem } from './FavouritesListItem';
import { FavouritesDocumentsListWrapper, optimizationStylesMiniFileCard } from './styled';
import { useCheckWindowSize } from '../../../../../shared/hooks/useCheckWindowSize';
import { theme } from '../../../../../shared/components/GlobalContainer/styled';
import { ClientGalleryFile, GalleryIdea } from '../../../../../shared/types/commonGallery';
import { getIdeaCoverOrFile } from '../../../../../shared/utils/createGallery';

type FavouritiesListProps = {
  files: (ClientGalleryFile | GalleryIdea)[];
  handleSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  isItemSelected: (id: string) => boolean;
};

const CustomImageList = MUIstyled(ImageList)<ImageListProps>(() => ({
  gap: '20px !important',
  '&.MuiImageList-masonry': {
    overflowY: 'unset',
    width: '100%',
    marginTop: 0,
    marginBottom: '20px',
    columnGap: '20px !important',
    gap: '20px !important',
  },
  [theme.breakpoints.down('xl')]: {
    columnCount: '3 !important',
    gap: '20px !important',
    '&.MuiImageList-masonry': {
      columnGap: '20px !important',
      gap: '20px !important',
    },
  },
  [theme.breakpoints.down('lg')]: {
    columnCount: '4 !important',
    gap: '10px !important',
    '&.MuiImageList-masonry': {
      marginBottom: '10px',
      columnGap: '10px !important',
      gap: '10px !important',
    },
  },
  [theme.breakpoints.down('md')]: {
    columnCount: '2 !important',
    gap: '8px !important',
    '&.MuiImageList-masonry': {
      marginBottom: '8px',
      columnGap: '8px !important',
      gap: '8px !important',
    },
  },
  [theme.breakpoints.down('sm')]: {
    columnCount: '2 !important',
    gap: '8px !important',
    '&.MuiImageList-masonry': {
      marginBottom: '8px',
      columnGap: '8px !important',
      gap: '8px !important',
    },
  },
  [theme.breakpoints.down('xs')]: {
    columnCount: '2 !important',
    gap: '8px !important',
    '&.MuiImageList-masonry': {
      marginBottom: '8px',
      columnGap: '8px !important',
      gap: '8px !important',
    },
  },
}));

export const FavouritesImageList = MUIstyled(CustomImageList)<ImageListProps>(() => ({
  [theme.breakpoints.up('xl')]: {
    columnCount: '4 !important',
    gap: '20px !important',
    '&.MuiImageList-masonry': {
      columnGap: '20px !important',
      gap: '20px !important',
    },
  },
}));

export const getDocuments = (
  files: (ClientGalleryFile | GalleryIdea)[],
): {
  docs: (ClientGalleryFile | GalleryIdea)[],
  // eslint-disable-next-line function-paren-newline
  otherFiles: (ClientGalleryFile | GalleryIdea)[],
} => {
  const docs: (ClientGalleryFile | GalleryIdea)[] = [];
  const otherFiles: (ClientGalleryFile | GalleryIdea)[] = [];

  files.forEach((item) => {
    const currentFile = getIdeaCoverOrFile(item);
    if (isDocument(currentFile)) {
      docs.push(item);
    } else {
      otherFiles.push(item);
    }
  });
  return { docs, otherFiles };
};

export const FavouritiesList: React.FC<FavouritiesListProps> = ({
  files,
  isItemSelected,
  handleSelect,
}) => {
  const { docs, otherFiles } = getDocuments(files);
  const { screenSize } = useCheckWindowSize();

  return (
    <>
      <FavouritesImageList
        variant={screenSize === 'mobile' ? 'standard' : 'masonry'}
      >
        {(screenSize === 'desktop' ? otherFiles : files).map((item) => (
          <FavouritesListItem
            key={item.id}
            file={item}
            isItemSelected={isItemSelected}
            handleSelect={handleSelect}
          />
        ))}
      </FavouritesImageList>
      <FavouritesDocumentsListWrapper>
        {docs?.map((item) => (
          <div style={{ position: 'relative' }} key={item.id}>
            <FileModalWindow file={item} />
            <MiniFileCard
              key={item?.id}
              file={getIdeaCoverOrFile(item)}
              handleCheck={handleSelect}
              isChecked={isItemSelected(item.id)}
              id={item?.id}
              large
              mobileStyle={optimizationStylesMiniFileCard}
            />
          </div>
        ))}
      </FavouritesDocumentsListWrapper>
    </>
  );
};
