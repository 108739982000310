import {
  CreatorForm,
  CreatorProfilePut,
  PlanEnum,
  PlanTypes,
} from '../../../shared/types';

export const setTimeToResendData = (inSeconds = 60000): void => {
  const nowSeconds = new Date().getTime();
  window.localStorage.setItem('resendInMs', String(nowSeconds + inSeconds));
};

export const getSettingsPutData = (data: CreatorForm): CreatorProfilePut => ({
  firstName: data.firstName,
  lastName: data.lastName,
  businessName: data.businessName,
  profileImage: data.profileImage,
  // plan:
  timezone: data.timezone,
  dateFormat: data.dateFormat,
  branding: data.branding,
  settings: {
    paymentsCollector: data.paymentsCollector || '',
    storeCurrency: data.storeCurrency || '',
    paypalEmail: data.paypalEmail || '',
    facebook: data.facebook || '',
    instagram: data.instagram || '',
    youtube: data.youtube || '',
    vimeo: data.vimeo || '',
    linkedIn: data.linkedIn || '',
    pinterest: data.pinterest || '',
    nameOnInvoice: data.nameOnInvoice,
    taxNumber: data.taxNumber,
    watermark: data.watermark,
    paymentCurrency: data.paymentCurrency,
    downloadsCart: data.downloadsCart,
    downloadsItem: data.downloadsItem,
    createsFavourites: data.createsFavourites,
    leavesComment: data.leavesComment,
    purchasesItem: data.purchasesItem,
    purchasesIdea: data.purchasesIdea,
    solvedIdeaReminder: data.solvedIdeaReminder,
    inviteFailed: data.inviteFailed,
    storageFull: data.storageFull,
    activeGalleryAboutExpiration: data.activeGalleryAboutExpiration,
    newFeatures: data.newFeatures,
    salesAndActivityReport: data.salesAndActivityReport,
    receivesReward: data.receivesReward,
  },
});

export const convertFileSize = (size?: number, decimal = 1): string => {
  if (!size) {
    return '0 KB';
  }
  const marker = 1024;
  const kiloBytes = marker;
  const megaBytes = marker * marker;
  const gigaBytes = marker * marker * marker;
  const teraBytes = marker * marker * marker * marker;

  // if (size < kiloBytes) return `${size} Bytes`;
  if (size < megaBytes) return `${(size / kiloBytes).toFixed(decimal)} KB`;
  if (size < gigaBytes) return `${(size / megaBytes).toFixed(decimal)} MB`;
  if (size < teraBytes) return `${(size / gigaBytes).toFixed(decimal)} GB`;

  return `${(size / teraBytes).toFixed(decimal)} TB`;
};

export const areObjectsSimilar = <T>(obj1: T, obj2: T): boolean => {
  if (
    typeof obj1 === 'object'
    && obj1 !== null
    && typeof obj2 === 'object'
    && obj2 !== null
  ) {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(obj1)) {
      if (typeof value === 'object' && value !== null) {
        return areObjectsSimilar(value, (obj2 as any)[key]);
      }

      if (value !== (obj2 as any)[key] && !!value !== !!(obj2 as any)[key]) {
        return false;
      }
    }
    return true;
  }
  return false;
};
