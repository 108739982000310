import React, {
  MutableRefObject,
  useRef,
  useState,
} from 'react';
import { PauseIcon, PlayIcon } from '../../../icons';
import {
  ButtonWrapper,
  PlayOrPauseButton,
  VideoElement,
  VideoWrapper,
} from './styled';
import { ImageElement } from '../ImageContainer/styled';

type VideoContainerProps = {
  videoUrl: string;
  autoPlay?: boolean;
  widthStretch?: boolean;
  videoThumbnail?: string;
  controls?: boolean;
  fileRef?: MutableRefObject<HTMLVideoElement | null>;
  littlePlayButton?: boolean;
  onLoad?: () => void;
};

export const VideoContainer: React.FC<VideoContainerProps> = ({
  videoThumbnail,
  videoUrl,
  autoPlay,
  widthStretch,
  controls,
  fileRef,
  onLoad,
  littlePlayButton,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isPlay, setIsPlay] = useState(false);

  const isPlaying = (fileRef?.current?.currentTime || 0) > 0.001
  && !fileRef?.current?.paused
  && !fileRef?.current?.ended
  && (fileRef?.current?.readyState || 0) > (videoRef?.current?.HAVE_CURRENT_DATA || 0);

  const handlePlayOrPause = () => {
    setIsPlay((prev) => !prev);
    if (isPlay) {
      videoRef.current?.pause();
    } else {
      videoRef.current?.play();
    }
  };

  if (videoRef.current) {
    window.localStorage.setItem('thumbnailVideoWidth', String(videoRef.current.videoWidth));
    window.localStorage.setItem('thumbnailVideoHeight', String(videoRef.current.videoHeight));
  }

  return (
    <VideoWrapper
      style={{ position: 'relative' }}
    >
      {videoUrl && (
        <VideoElement
          // autoPlay={autoPlay}
          // poster={videoThumbnail}
          loop
          muted={!controls}
          id={videoUrl}
          preload="metadata"
          ref={controls || !fileRef ? videoRef : fileRef}
          controls={!!controls}
          controlsList="nodownload"
          onLoadedData={onLoad}
        >
          <source src={`${videoUrl}#t=0.001`} type="video/mkv" />
          <source src={`${videoUrl}#t=0.001`} type="video/ogg" />
          <source src={`${videoUrl}#t=0.001`} type="video/avi" />
          <source src={`${videoUrl}#t=0.001`} type="video/mp4" />
          {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
          {videoThumbnail && controls
            ? (
              <ImageElement
                id="thumbnail-image"
                src={videoThumbnail}
                alt="thumbnail"
                style={{
                  position: 'absolute',
                  top: '0',
                  left: '0',
                  width: '100%',
                  height: '100%',
                  visibility: videoThumbnail && controls ? 'visible' : 'hidden',
                }}
              />
            )
            : null}
        </VideoElement>
      )}
      {videoThumbnail && !controls
        ? (
          <ImageElement
            id="thumbnail-image"
            src={videoThumbnail}
            alt="thumbnail"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
              visibility: videoThumbnail && !controls ? 'visible' : 'hidden',
            }}
          />
        )
        : null}
      {/* {!controls
      && (
      <ButtonWrapper
        isPlay={isPlay || isPlaying}
      >
        <PlayOrPauseButton
          little={!!littlePlayButton}
          type="button"
          onClick={handlePlayOrPause}
        >
          {isPlay || isPlaying ? <PauseIcon /> : <PlayIcon />}
        </PlayOrPauseButton>
      </ButtonWrapper>
      )} */}
    </VideoWrapper>
  );
};
