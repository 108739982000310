import { createAsyncThunk } from '@reduxjs/toolkit';
import { GalleryFileBaseWithUrls } from '../../../../../shared/types/commonGallery';

export const fetchTryAgainFiles = createAsyncThunk(
  'gallery/fetchTryAgainFiles',
  async (files: GalleryFileBaseWithUrls[]) => {
    try {
      return files.map((item) => item.id);
    } catch (err: any) {
      return err.response.data;
    }
  },
);
