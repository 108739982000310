import React, { useState } from 'react';
import {
  BetaCheckoutWindowContainer,
  BetaCheckoutWindowWrp,
} from './styled';
import * as variables from '../../../constants/styles';
import { BaseModalWindow } from '../../../shared/components/BaseModalWindow/BaseModalWindow';
import { BetaCheckoutForm } from './BetaCheckoutForm';
import { BetaCheckoutDescription } from './BetaCheckoutDescription';

export const BetaCheckoutWindow: React.FC = () => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    // setIsOpen(false);
  };

  return (
    <BaseModalWindow
      transparentBackground
      isOpen={isOpen}
      handleClose={handleClose}
      style={{
        paper: {
          maxWidth: '850px',
          background: 'transparent',
          borderRadius: '10px',
          '&::-webkit-scrollbar': {
            width: '6px',
            marginLeft: '-10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: variables.colorGreyLight,
            borderRadius: '163px',
            backgroundClip: 'padding-box',
          },
          '&::-webkit-scrollbar-track': {
            display: 'none',
            borderRadius: '163px',
          },
        },
      }}
    >
      <BetaCheckoutWindowContainer>
        <BetaCheckoutWindowWrp>
          <BetaCheckoutForm />
          <BetaCheckoutDescription />
        </BetaCheckoutWindowWrp>
      </BetaCheckoutWindowContainer>
    </BaseModalWindow>
  );
};
