import { Grid } from '@material-ui/core';
import { FormikErrors, useFormikContext, FormikTouched } from 'formik';
import React, { ChangeEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CoverContainer, CoverWrapper, MiniFileCardsContainer } from './styled';
import { PlusIcon } from '../styled';
import { ErrorMessage } from '../../../../../../../../shared/components/styled';
import { getFileInContainer } from '../../../../../../../../shared/utils/createGallery';
import { MiniChooseFileCard } from '../../../../../../../../shared/components/GalleryCreator/styled';
// import { useSendAndSetFiles } from '../../../../../../hooks/useSendAndSetFiles';
import { AttachedFilesList } from './AttachedFilesList';
import { GalleryFileBaseWithUrls, GalleryIdea } from '../../../../../../../../shared/types/commonGallery';
import { CreatorGalleryForm } from '../../../../../../../../shared/types/creatorGallery';

type IdeaFilesInputProps = {
  idea: GalleryIdea;
  ideaField: string;
  errors: FormikErrors<GalleryIdea> | null;
  touched: FormikTouched<GalleryIdea> | null;
};
const arr = [null, null, null];

export const IdeaFilesInput: React.FC<IdeaFilesInputProps> = ({
  idea,
  ideaField,
  errors,
  touched,
}) => {
  const { t } = useTranslation('createNewGallery');
  const { setFieldValue } = useFormikContext<CreatorGalleryForm>();
  // const { sendAndSetFiles } = useSendAndSetFiles(
  //   `${ideaField}.files`,
  //   idea?.files,
  // );

  const arrFiles = useMemo(() => {
    const endIndex = idea.files.length > 3 ? 0 : 3 - idea.files.length;
    return [...(idea?.files || []), ...arr.slice(0, endIndex)];
  }, [idea?.files]);

  const handleClick = (files: File[]) => {
    // sendAndSetFiles(files);
  };

  const handleSetCoverFile = (
    e: ChangeEvent<HTMLInputElement>,
    file: GalleryFileBaseWithUrls,
  ) => {
    if (typeof file !== 'number') setFieldValue(`${ideaField}.cover`, file);
  };

  const handleDeleteFile = (id: string) => {
    setFieldValue(
      `${ideaField}.files`,
      idea?.files.filter((item) => item.id !== id),
    );
  };

  const coverFile = useMemo(
    () => getFileInContainer({ file: idea?.cover }),
    [idea?.cover],
  );

  return (
    <Grid
      container
      item
      sm={5}
      justifyContent="flex-end"
      alignContent="flex-start"
    >
      <CoverContainer>
        <CoverWrapper>
          {idea.cover ? coverFile : <p>{t('secondStep.chooseIdeaCover')}</p>}
        </CoverWrapper>
        <ErrorMessage>
          {errors?.cover && touched?.cover ? errors?.cover : null}
        </ErrorMessage>
      </CoverContainer>
      <MiniFileCardsContainer width={(((arrFiles?.length || 0) + 1) * 122) / 2}>
        <div>
          <MiniChooseFileCard>
            <input
              name={`${ideaField}.cover`}
              type="file"
              multiple
              onChange={(e) => handleClick(Array.from(e.target.files || []))}
            />
            <PlusIcon theme="small" />
          </MiniChooseFileCard>
          <AttachedFilesList
            arrFiles={arrFiles}
            ideaCoverFileId={idea?.cover?.id || ''}
            handleSetCoverFile={handleSetCoverFile}
            handleDeleteFile={handleDeleteFile}
          />
        </div>
      </MiniFileCardsContainer>
    </Grid>
  );
};
