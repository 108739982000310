/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable max-len */
import React, { ReactNode, useState } from 'react';
import { downloadFiles } from '../../../../api/client/clientGallery';
import { CustomAlert } from '../../../../shared/components/CustomAlert/CustomAlert';

type ReturnObj = {
  handleDownloadSingleFile: (fileId: string, fileName: string, galleryId: string) => void;
  downloadErrorAlert: ReactNode;
  isLoadingFile: string[];
};

export const useFreeFilesDownload = (): ReturnObj => {
  const [error, setError] = useState('');
  const [isLoadingFile, setIsLoadingFile] = useState<string[]>([]);

  const handleDownloadSingleFile = async (
    fileId: string,
    fileName: string,
    galleryId: string,
  ) => {
    setIsLoadingFile((prev) => [...prev, fileId]);
    const res = await downloadFiles.getDownloadSingleFileUrl(galleryId, fileId);
    const url = res.data;
    fetch(url)
      .then((res) => {
        if (!res.ok) {
          setIsLoadingFile((prev) => prev.filter((item) => item !== fileId));
          setError('Download process failed. Please, try again later');
        }
        return res.blob();
      })
      .then((file) => {
        const tUrl = URL.createObjectURL(file);
        const tmp1 = document.createElement('a');
        tmp1.href = tUrl;
        tmp1.download = fileName;
        document.body.appendChild(tmp1);
        tmp1.click();
        URL.revokeObjectURL(tUrl);
        tmp1.remove();
      })
      .catch(() => {
        setError('Download process failed. Please, try again later');
      })
      .finally(() => setIsLoadingFile((prev) => prev.filter((item) => item !== fileId)));
  };

  return {
    handleDownloadSingleFile,
    isLoadingFile,
    downloadErrorAlert: (
      <CustomAlert
        isOpenWindow={!!error}
        message={error || ''}
        type="error"
        title="Oops..."
        onClose={() => setError('')}
        onClick={() => setError('')}
      />
    ),
  };
};
