import { useFormikContext } from 'formik';
import React, {
  useRef, useState, MouseEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LittleTickIcon } from '../../../../../../shared/icons';
import { ModalWindowGalleryPreview } from './NewGalleryCreatorFifthStep/ModalWindowGalleryPreview';
import {
  ProgressBarContainer,
  ProgressBarDot,
  ProgressBarDotWrapper,
  ProgressBarText,
  ProgressBarWrapper,
  ProgressLineInside,
} from './styled';
import { CreatorGalleryForm } from '../../../../../../shared/types/creatorGallery';

type ProgressBarProps = {
  step: number;
  clickStep: (step: number) => void;
};

type ProgressBarElement = {
  label: string;
};

export const useProgressBarLabels = (): ProgressBarElement[] => {
  const { t } = useTranslation('createNewGallery');
  return [
    { label: t('progressBar.firstStep') },
    { label: t('progressBar.secondStep') },
    { label: t('progressBar.thirdStep') },
    { label: t('progressBar.fourthStep') },
  ];
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ step, clickStep }) => {
  const { t } = useTranslation('createNewGallery');
  const { state } = useLocation();
  const [isOpenGalleryPreview, setIsOpenGalleryPreview] = useState(false);
  const refLastMaxStep = useRef<number>(1);
  const { values } = useFormikContext<CreatorGalleryForm>();
  const stageLabels = useProgressBarLabels();
  const isEdit = state?.status === 'edited';

  if (step > refLastMaxStep.current) {
    refLastMaxStep.current = step;
  }

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    const clickedStep = +e.currentTarget.id;
    const isNextOrAllPreviousStep = clickedStep < refLastMaxStep.current
    || values.step === clickedStep - 1;

    if (
      isNextOrAllPreviousStep
      || clickedStep === refLastMaxStep.current
      || clickedStep === refLastMaxStep.current + 1
      || isEdit
      || clickedStep < refLastMaxStep.current
    ) {
      if (clickedStep < 5) clickStep(clickedStep);
      if (clickedStep === 5) setIsOpenGalleryPreview(true);
    }
  };

  const buttons = stageLabels.map((item, index) => {
    const stepNumber = index + 1;
    return (
      <div key={item.label}>
        <ProgressBarDot
          id={`${stepNumber}`}
          stageNumber={stepNumber}
          status={`${refLastMaxStep.current === stepNumber ? 'active' : ''} ${
            stepNumber < refLastMaxStep.current ? 'done' : ''
          }`}
        >
          <ProgressBarDotWrapper
            type="submit"
            id={`${stepNumber}`}
            onClick={handleClick}
            className={`progress-dot  progress-dot-${stepNumber}`}
          />
          {stepNumber < refLastMaxStep.current && values.step !== stepNumber ? (
            <LittleTickIcon />
          ) : (
            <span>{stepNumber}</span>
          )}
        </ProgressBarDot>
        <ProgressBarText theme={`${stepNumber}`}>
          {item.label}
        </ProgressBarText>
      </div>
    );
  });

  return (
    <ProgressBarWrapper id="progress-bar">
      <ProgressBarContainer>
        <ProgressLineInside maxStepNumber={refLastMaxStep.current} currentStep={values.step} />
        {buttons}
        <ModalWindowGalleryPreview
          clickStep={clickStep}
          setIsOpen={setIsOpenGalleryPreview}
          isOpen={isOpenGalleryPreview}
          button={(
            <ProgressBarDot
              id="5"
              stageNumber={5}
              status={`${refLastMaxStep.current === 5 ? 'active' : ''} ${
                refLastMaxStep.current >= 5 ? 'done' : ''
              }`}
            >
              <ProgressBarDotWrapper
                id="5"
                onClick={handleClick}
                type="submit"
                className="progress-dot  progress-dot-5"
              />
              {refLastMaxStep.current >= 5 && values.step !== 5
                ? <LittleTickIcon /> : <span>5</span>}
            </ProgressBarDot>
          )}
        />
        <ProgressBarText theme="5">
          {t('progressBar.fifthStep')}
        </ProgressBarText>
      </ProgressBarContainer>
    </ProgressBarWrapper>
  );
};
