import { Grid } from '@material-ui/core';
import React from 'react';
import { useAuth } from '@clerk/clerk-react';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../../../shared/hooks/useTypedSelector';
import { DeleteIcon } from '../../../../../../../../../shared/icons';
import { getFormattedDateOrDateName } from '../../../../../../../../../shared/utils';
import { MediumBlackTextMgTB, RegisteredEmailDelete } from './styled';
import { fetchDeleteCreatorViews } from '../../../../../../../redux/gallery/interactions/interactionsCreatorThunk';
import { PinkLoader } from '../../../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { RegisteredEmail } from '../../../../../../../../../shared/types/commonGallery';

type RegisteredEmailRowProps = {
  row: RegisteredEmail;
  galleryId: string;
};

export const RegisteredEmailRow: React.FC<RegisteredEmailRowProps> = ({
  row,
  galleryId,
}) => {
  const dateFormat = useTypedSelectorCreator((state) => state.creator?.currentUser?.dateFormat) || 'mm/dd/yyyy';
  const timezone = useTypedSelectorCreator((state) => state.creator?.currentUser?.timezone);
  const formattedDate = getFormattedDateOrDateName(row.lastVisitDate, dateFormat, timezone, true);
  const loading = useTypedSelectorCreator((state) => state.interactions.loadingDelete);
  const { getToken } = useAuth();
  const dispatch = useCreatorAppDispatch();

  const handleDeleteView = () => {
    dispatch(fetchDeleteCreatorViews({ galleryId, email: row.email, getToken }));
  };

  return (
    <Grid container item sm={12}>
      <Grid item sm={6}>
        <MediumBlackTextMgTB>{row?.email || 'unknown'}</MediumBlackTextMgTB>
      </Grid>
      <Grid item sm={4}>
        <MediumBlackTextMgTB>
          {formattedDate || 'not indicated'}
        </MediumBlackTextMgTB>
      </Grid>
      <Grid item sm={2}>
        <RegisteredEmailDelete>
          <button type="button" aria-label="delete" onClick={handleDeleteView}>{loading ? <PinkLoader size="xs" /> : <DeleteIcon />}</button>
        </RegisteredEmailDelete>
      </Grid>
    </Grid>
  );
};
