import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  BetaDescriptionConteiner,
  ListSubItem,
  ProtectedPageDescriptionText,
  ProtectedPageDescriptionTitle,
  ProtectedPageImage,
  SubscriptionListItem,
  SubscriptionTickWrapper,
} from './styled';
import {
  SimpleWhiteText,
} from '../../../shared/styles';
import {
  TickIcon,
} from '../../../shared/icons';

const planDetails = [
  {
    main: 'Lifetime discount for first 100 members',
    sub: '20% off all future annual subscription fees',
  },
  { main: 'Earn more from your client projects', sub: '' },
  { main: 'Present your client work professionally', sub: '' },
  { main: '50GB photo + video storage', sub: '' },
  { main: "Access to all Pro features as soon as they're available", sub: '' },
  {
    main: 'Guarantee',
    sub: 'Not satisfied? Simply opt out of the annual subscription within the first 30 days and only pay for 1 month',
  },
];

export const BetaCheckoutDescription: React.FC = () => {
  const { t } = useTranslation('signInBeta');

  return (
    <BetaDescriptionConteiner>
      <ProtectedPageImage style={{ backgroundPosition: '50% 80%' }} />
      <ProtectedPageDescriptionTitle
        style={{ fontWeight: 500, marginBottom: '30px' }}
      >
        {t('checkoutTitle')}
      </ProtectedPageDescriptionTitle>
      <ProtectedPageDescriptionText>
        {planDetails.map((item) => (
          <SubscriptionListItem key={item.main}>
            <SubscriptionTickWrapper>
              <TickIcon isGradient={false} />
            </SubscriptionTickWrapper>
            <div>
              <SimpleWhiteText>{item.main}</SimpleWhiteText>
              {item.sub && (
                <ul style={{ margin: '0px 0px 0px 25px', padding: '0px' }}>
                  <ListSubItem>{item.sub}</ListSubItem>
                </ul>
              )}
            </div>
          </SubscriptionListItem>
        ))}
      </ProtectedPageDescriptionText>
    </BetaDescriptionConteiner>
  );
};
