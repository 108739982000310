import { isImage, isVideo, isDocument } from '..';
import { ClientOrCreatorGalleryFile } from '../../types/commonGallery';

type ReturnObj = {
  arrImagesAndVideos: ClientOrCreatorGalleryFile[];
  arrDocuments: ClientOrCreatorGalleryFile[];
}

export const getImagesVideosDocuments = (files: ClientOrCreatorGalleryFile[]): ReturnObj => {
  const arrImagesAndVideos: ClientOrCreatorGalleryFile[] = [];
  const arrDocuments: ClientOrCreatorGalleryFile[] = [];
  files?.forEach((item) => {
    if (isImage(item) || isVideo(item)) {
      arrImagesAndVideos.push(item);
    }
    if (isDocument(item)) {
      arrDocuments.push(item);
    }
  });

  return { arrImagesAndVideos, arrDocuments };
};
