import React, { useRef } from 'react';
import { ImageElement, ImageWrapper } from './styled';

type ImageContainerProps = {
  imageUrl: string;
  widthStretch?: boolean;
};

export const ImageContainer: React.FC<ImageContainerProps> = ({
  imageUrl,
  widthStretch,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  return (
    <ImageWrapper ref={containerRef} widthStretch={!!widthStretch} hasImage={!!imageUrl}>
      {imageUrl && (
        <ImageElement
          src={imageUrl}
          data-src={imageUrl}
          alt={imageUrl}
          loading="lazy"
        />
      )}
    </ImageWrapper>
  );
};
