import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '@clerk/clerk-react';
import { BaseSecondaryButton, PrimaryWhiteButton } from '../../../../../../../../shared/styles';
import { LoadingForRequest } from '../../../../../../../../shared/components/LoadingForRequest/LoadingForRequest';
import { useDialog } from '../../../../../../../../shared/hooks/useDialog';
import { useSaveGallery } from '../../hooks/useSaveGallery';
import { CreatorOnboardingContext } from '../../../../../../context/CreatorOnboardingContext';
import { fetchDeleteCreatorGallery } from '../../../../../../redux/gallery/galleryCreatorThunk';
import { useCreatorAppDispatch } from '../../../../../../../../shared/hooks/useTypedSelector';
import { setSuccessDeleteFalse, setSuccessSaveFalse, setSuccessUpdateFalse } from '../../../../../../redux/gallery/galleryCreatorSlice';
import * as urls from '../../../../../../../../constants/urls';
import { CreatorGalleryForm } from '../../../../../../../../shared/types/creatorGallery';

type SaveAndCloseGalleryProps = {
  disabled?: boolean;
};

export const SaveAndCloseGallery: React.FC<SaveAndCloseGalleryProps> = ({ disabled }) => {
  const { t } = useTranslation(['basicComponents', 'gallery']);
  const { values, initialValues } = useFormikContext<CreatorGalleryForm>();
  const {
    handleUpdateGallery, isLoading, isSuccess, errorAlert,
  } = useSaveGallery();
  const { isStudy } = useContext(CreatorOnboardingContext);
  const { state } = useLocation();
  const dispatch = useCreatorAppDispatch();
  const isLastStep = values.step >= 5;
  const isEdit = state?.status === 'edited';
  // eslint-disable-next-line no-nested-ternary
  const buttonText = isEdit ? 'Save changes' : (isLastStep ? t('buttons.saveClose') : t('buttons.saveToDrafts'));
  const { getToken } = useAuth();
  const navigate = useNavigate();

  const handleSave = () => {
    const status = isLastStep || isEdit ? 'active' : 'draft';
    handleUpdateGallery(values, status);
  };
  const { dialogWindow, setIsOpen } = useDialog<CreatorGalleryForm>(
    'confirmation',
    values,
    handleSave,
    isLastStep || isEdit ? t('gallery:saveGallery.textActive') : t('gallery:saveGallery.textDraft'),
    t('gallery:saveGallery.question'),
  );

  useEffect(() => {
    const handleTabClose = (event: BeforeUnloadEvent) => {
      if (isEdit) {
        handleUpdateGallery(initialValues, 'active');
        return;
      }
      event.preventDefault();
      window.addEventListener('unload', () => dispatch(fetchDeleteCreatorGallery({ id: values.id, getToken })));
      event.returnValue = 'The gallery wasn\'t saved.';
    };

    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
      window.removeEventListener('unload', () => dispatch(fetchDeleteCreatorGallery({ id: values.id, getToken })));
    };
  }, []);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setSuccessSaveFalse());
        dispatch(setSuccessUpdateFalse());
        dispatch(setSuccessDeleteFalse());
        navigate(urls.creatorRootUrl, { replace: true });
      }, 570);
    }
  }, [isSuccess]);

  return (
    <div>
      {values.step < 5 && (
      <BaseSecondaryButton
        type="button"
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        color="secondary"
        fontWeight={600}
      >
        {buttonText}
      </BaseSecondaryButton>
      )}
      {values.step >= 5 && (
      <PrimaryWhiteButton
        type="button"
        onClick={() => setIsOpen(true)}
        disabled={disabled}
        color="pink"
      >
        {buttonText}
      </PrimaryWhiteButton>
      )}
      {(isLoading || isSuccess) && (
        <LoadingForRequest
          text={t('changesSaved')}
          isLoading={isLoading}
          isSuccess={isSuccess}
          goToMainPage
        />
      )}
      {!isStudy && dialogWindow}
      {errorAlert}
    </div>
  );
};
