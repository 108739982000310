import React from 'react';
import { SectionItem } from './SectionItem/SectionItem';
import { SectionsListWrapper } from '../styled';
import { ClientGallerySection } from '../../../../../shared/types/clientGallery';

type SectionsListProps = {
  sections: ClientGallerySection[];
}

export const SectionsList: React.FC<SectionsListProps> = ({
  sections,
}) => (
  <SectionsListWrapper>
    {sections?.map((item) => (
      <SectionItem sections={sections} section={item} key={item?.id} />
    ))}
  </SectionsListWrapper>
);
