import { useFormikContext } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { MediumBlackTextMg } from './styled';
import { CommonSelect } from '../../../../../../../../shared/components/FormElements/CommonSelect/CommonSelect';
import { CustomMenuItem } from '../../../../../../../../shared/components/FormElements/CommonSelect/styled';
import { CreatorGalleryForm } from '../../../../../../../../shared/types/creatorGallery';

export const ReminderEmailWhitelistedEmails: React.FC = () => {
  const { t } = useTranslation('createNewGallery');

  const {
    values, errors, handleChange, touched, setFieldValue,
  } = useFormikContext<CreatorGalleryForm>();

  const { whitelistEmails, whitelistRemindEmails: whoFromWhitelisted } = values;

  const menuItems = useMemo(() => whitelistEmails?.map((item) => (
    <CustomMenuItem key={item} value={item}>
      {item || ''}
    </CustomMenuItem>
  )), [whitelistEmails]);

  useEffect(() => {
    const updatesEmails = whoFromWhitelisted?.filter((item) => whitelistEmails?.includes(item));
    setFieldValue('whitelistRemindEmails', updatesEmails);
  }, [menuItems]);

  return (
    <div>
      <MediumBlackTextMg>
        {t('fourthStep.reminder.who.whitelistedEmails')}
      </MediumBlackTextMg>
      <CommonSelect
        error={errors.whitelistRemindEmails}
        value={values.whitelistRemindEmails || []}
        name="whitelistRemindEmails"
        placeholder={t('fourthStep.reminder.who.whitelistedEmailsPlaceholder')}
        handleChange={handleChange}
        touched={touched.whitelistRemindEmails}
        menuItems={menuItems || []}
        setFieldValue={setFieldValue}
        disabled={!values.whitelistEmails?.length}
        multiple
      />
    </div>
  );
};
