/* eslint-disable */
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSaveCroppedImage } from '../../../../../../../../shared/hooks/useSaveCroppedImage';
import { BaseModalWindow } from '../../../../../../../../shared/components/BaseModalWindow/BaseModalWindow';
import { BaseSecondaryButton } from '../../../../../../../../shared/styles/Buttons';
import { ClosePreviewButtonWrapper } from './styled';
import { FileRole } from '../../../../../../types/gallery';
import { useUploadFiles } from '../../../../../../hooks/useUploadFiles';
import { CreatorGalleryForm } from '../../../../../../../../shared/types/creatorGallery';

type GalleryCoversEditorProps = {
  children: ReactElement;
};

export const GalleryCoversEditor: React.FC<GalleryCoversEditorProps> = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { values, setFieldValue } = useFormikContext<CreatorGalleryForm>();
  const { t } = useTranslation('createNewGallery');
  const saveCroppedImage = useSaveCroppedImage(setFieldValue);
  const { uploading, onUpload, stateFiles } = useUploadFiles();

  const image = values.coverFile?.original;

  // doesn't work on beta
  const extraCover1 = values.extraCover1 || null;
  const extraCover2 = values.extraCover2 || null;

  const handleClose = async () => {
    if (extraCover1) {
      saveCroppedImage('extraCover1.desktop', extraCover1, 'extraCover1desktop');
      saveCroppedImage('extraCover1.tablet', extraCover1, 'extraCover1tablet');
      saveCroppedImage('extraCover1.mobile', extraCover1, 'extraCover1mobile');
    }
    if (extraCover2) {
      saveCroppedImage('extraCover2.desktop', extraCover2, 'extraCover2desktop');
      saveCroppedImage('extraCover2.tablet', extraCover2, 'extraCover2tablet');
      saveCroppedImage('extraCover2.mobile', extraCover2, 'extraCover2mobile');
    }
    const croppedDesktopImg = await saveCroppedImage('coverFile.desktop', image, 'desktop');
    const croppedTabletImg = await saveCroppedImage('coverFile.tablet', image, 'tablet');
    const croppedMobileImg = await saveCroppedImage('coverFile.mobile', image, 'mobile');

    if(typeof croppedDesktopImg !== 'undefined') {
      const fl = new DataTransfer();
      fl.items.add(croppedDesktopImg);
      croppedTabletImg && fl.items.add(croppedTabletImg);
      croppedMobileImg && fl.items.add(croppedMobileImg);
      
      onUpload({
        files: Array.from(fl.files),
        galleryId: values.id,
        fileRole: FileRole.GalleryCoverCropped
      })
    }

    setTimeout(() => setIsOpen(false), 400);
  };

  return (
    <>
      <button
        id="preview"
        type="button"
        onClick={() => setIsOpen(true)}
        disabled={!values.coverFile.original}
      >
        <span>{t('preview')}</span>
      </button>
      <BaseModalWindow
        fullScreen
        isOpen={isOpen}
        handleClose={handleClose}
      >
        <ClosePreviewButtonWrapper>
          <BaseSecondaryButton type="button" onClick={handleClose}>
            {t('closePreview')}
          </BaseSecondaryButton>
        </ClosePreviewButtonWrapper>
        <div style={{ boxSizing: 'border-box', padding: '0px 40px 40px 40px' }}>
          {children}
        </div>
      </BaseModalWindow>
    </>
  );
};
