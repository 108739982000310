import React, {
  ReactElement, useState,
} from 'react';
import { ClientGalleryGet, CustomFile, GalleryComment } from '../../../../shared/types';
import { CommentAreaWindow } from '../../components/CommentArea/CommentAreaWindow';
import { FileAndCommentsWindowContainer } from '../../components/CommentArea/FileAndCommentsWindowContainer';

type ReturnObj = {
  handleOpenComments: () => void;
  commentArea: ReactElement | null;
  isCommentsOpen: boolean;
  numberOfComments: number;
};

type UseCommentProps = {
  isFileOpen?: boolean;
  file?: CustomFile | null;
  comments?: GalleryComment[];
  fileId: string;
  gallery: ClientGalleryGet | null;
  addComment: (comment: string) => void;
  deleteComment: (commentId: string) => void;
};

export const useComment = ({
  isFileOpen,
  file,
  fileId,
  gallery,
  addComment,
  deleteComment,
}: UseCommentProps): ReturnObj => {
  const [isCommentsOpen, setIsCommentOpen] = useState(false);

  let comments: GalleryComment[] = [];

  // eslint-disable-next-line max-len
  gallery?.sections?.forEach((section) => [...(section?.files || []), ...(section?.ideas || [])]?.forEach((item) => {
    if (item.id === fileId) {
      comments = item.comments || [];
    }
  }));
  const numberOfComments = comments?.length || 0;

  const handleOpenComments = () => {
    setIsCommentOpen(true);
  };
  const handleCloseComments = () => {
    setIsCommentOpen(false);
  };
  let commentArea = null;

  if (isCommentsOpen) {
    if (isFileOpen) {
      commentArea = (
        <FileAndCommentsWindowContainer
          isOpen={isCommentsOpen}
          handleClose={handleCloseComments}
          file={file}
          comments={comments || []}
          dateFormat={gallery?.dateFormat || ''}
          addComment={addComment}
          deleteComment={deleteComment}
        />
      );
    } else {
      commentArea = (
        <CommentAreaWindow
          comments={comments || []}
          handleClose={handleCloseComments}
          isOpen={isCommentsOpen}
          dateFormat={gallery?.dateFormat || ''}
          addComment={addComment}
          deleteComment={deleteComment}
        />
      );
    }
  }

  return {
    handleOpenComments,
    commentArea,
    isCommentsOpen,
    numberOfComments,
  };
};
