import { GalleryFileBaseWithUrls } from '../../types/commonGallery';

const createImage = (url: string) => new Promise((resolve, reject) => {
  const image = new Image();
  image.addEventListener('load', () => resolve(image));
  image.addEventListener('error', (error) => reject(error));
  image.crossOrigin = 'Anonymous';
  image.setAttribute('crossOrigin', '');
  image.src = url;
});

export async function getCroppedVideoThumbnail(
  originalImage: GalleryFileBaseWithUrls,
  filename: string,
  pixelCrop: { width: number; height: number; x: number; y: number },
): Promise<File> {
  try {
    const canvas = document.createElement('canvas');
    const image: any = await createImage(originalImage.url || '');
    const ctx = canvas.getContext('2d');
    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));
    const newRatio = pixelCrop.height / pixelCrop.width;
    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;
    let newHeight = image.height;
    let newWidth = image.height / newRatio;
    if (image.width < newWidth) {
      newHeight = image.width * newRatio;
      newWidth = image.width;
    }
    if (image.width > image.height && pixelCrop.width < pixelCrop.height) {
      newHeight = image.height;
      newWidth = image.height / newRatio;
    }
    if (image.height < newHeight) {
      newHeight = image.height;
      newWidth = image.height / newRatio;
    }

    // draw rotated image and store data.
    ctx?.drawImage(
      image,
      (image.width < image.height ? 0 : ((image.width - newWidth) / 2)),
      (image.width < image.height ? ((image.height - newHeight) / 2) : 0),
      newWidth,
      newHeight,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5,
      newWidth,
      newHeight,
    );
    const data = ctx?.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = newWidth;
    canvas.height = newHeight;

    // paste generated rotate image with correct offsets for x,y crop values.
    if (data) {
      ctx?.putImageData(
        data,
        Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
        Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
      );
    }
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        if (file) {
        /* eslint-disable-next-line prefer-template */
          const newFilename = filename + '.webp';
          const newFile = new File([file], newFilename, {
            type: file.type,
          });
          resolve(newFile);
        }
      }, 'image/webp', 0.7);
    });
  } catch {
    const canvas = document.createElement('canvas');
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        if (file) {
        /* eslint-disable-next-line prefer-template */
          const newFilename = filename + '.webp';
          const newFile = new File([file], newFilename, {
            type: file.type,
          });
          resolve(newFile);
        }
      }, 'image/webp', 0.7);
    });
  }
}

export async function getCroppedImg(
  originalImage: GalleryFileBaseWithUrls,
  filename: string,
  pixelCrop: { width: number; height: number; x: number; y: number },
): Promise<File> {
  const canvas = document.createElement('canvas');
  const image: any = await createImage(originalImage.url || '');

  const ctx = canvas.getContext('2d');
  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // draw rotated image and store data.
  ctx?.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5,
  );
  const data = ctx?.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  if (data) {
    ctx?.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y),
    );
  }
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      if (file) {
        /* eslint-disable-next-line prefer-template */
        const newFilename = filename + '.webp';
        const newFile = new File([file], newFilename, {
          type: file.type,
        });
        resolve(newFile);
      }
    }, 'image/webp', 0.7);
  });
}
