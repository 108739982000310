/* eslint-disable */
import React, { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ButtonCard } from './styled';
import { FileRole } from '../../../../../types/gallery';
import { OnUploadProps } from '../../../../../hooks/useUploadFiles';
import { CreatorGallerySection } from '../../../../../../../shared/types/creatorGallery';

type DragAndDropFilesProps = {
  section: CreatorGallerySection;
  indexSection: number;
  gallery: string;
  onUpload: (props: OnUploadProps) => void;
};

export const DragAndDropFiles: React.FC<DragAndDropFilesProps> = ({
  section,
  indexSection,
  gallery,
  onUpload,
}) => {
  const { t } = useTranslation('createNewGallery');
  // to change color, when user drags files
  const [isDragProcess, setIsDragProcess] = useState(false);

  const handleClick = (e: ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.currentTarget.files ?? []);

    onUpload({
      files,
      galleryId: gallery,
      fileRole: FileRole.Regular,
      sectionId: section.id,
      existedFiles: section.files,
    });
  };

  return (
    <ButtonCard isDragProcess={isDragProcess}>
      <input
        name={`sections[${indexSection}].files`}
        multiple
        type="file"
        onDragEnter={() => setIsDragProcess(true)}
        onDrop={() => setIsDragProcess(false)}
        onChange={(e) => handleClick(e)}
      />
      <span>
        <Trans i18nKey="secondStep.addContent" t={t}>
          Drag and drop to add files
          <p>or</p>
          <u>upload from your computer</u>
        </Trans>
      </span>
    </ButtonCard>
  );
};
