import { useFormikContext } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  NextAndBackButtonsWrapper, ButtonBack, ArrowBoldIcon, ButtonNext,
} from './styled';
import { CreatorGalleryForm } from '../../../../../../../shared/types/creatorGallery';

type NextAndBackButtonsProps = {
  handleLeftClick: () => void;
  handleRightClick: () => void;
};

export const NextAndBackButtons: React.FC<NextAndBackButtonsProps> = ({
  handleLeftClick,
  handleRightClick,
}) => {
  const { t } = useTranslation('basicComponents');
  const { values } = useFormikContext<CreatorGalleryForm>();

  const noNextButton = values.step === 5;
  const noBackButton = values.step <= 1;

  return (
    <NextAndBackButtonsWrapper>
      {!noBackButton && (
        <ButtonBack
          // direction="back"
          type="submit"
          onClick={handleLeftClick}
          // style={buttonStyles}
          id="back"
        >
          <ArrowBoldIcon direction="left" />
          {/* <MediumGreyText style={{ textTransform: 'uppercase' }} fontWeight={500}> */}
            {t('buttons.back')}
          {/* </MediumGreyText> */}
        </ButtonBack>
      )}
      {!noNextButton && (
        <ButtonNext
          color="pink"
          type="submit"
          onClick={handleRightClick}
          id="next"
        >
            {t('buttons.next')}
          <ArrowBoldIcon direction="right" />
        </ButtonNext>
      )}
    </NextAndBackButtonsWrapper>
  );
};
