import React from 'react';
import { useFormikContext } from 'formik';
import { ModalCloseButtons } from '../../../../../../../shared/styles';
import { SaveAndCloseGallery } from '../NewGalleryCreatorButtons/SaveAndCloseGallery/SaveAndCloseGallery';
import { ButtonsGroupWrapper } from './styled';
import { NextAndBackButtons } from '../NewGalleryCreatorButtons/NextAndBackButtons';
import { CreatorGalleryForm } from '../../../../../../../shared/types/creatorGallery';

type GalleryPreviewButtonsGroupProps = {
  handleClose: () => void;
  clickStep: (step: number) => void;
};

export const GalleryPreviewButtonsGroup: React.FC<GalleryPreviewButtonsGroupProps> = ({
  handleClose,
  clickStep,
}) => {
  const {
    values, handleSubmit,
  } = useFormikContext<CreatorGalleryForm>();

  const { step } = values;

  const handleLeftClick = () => {
    if (step === 5) {
      const prevStage = step - 1;
      clickStep(prevStage);
      handleSubmit();
      handleClose();
    } else {
      handleClose();
    }
  };
  const handleRightClick = () => {
    const prevStage = 5;
    clickStep(prevStage);
    handleSubmit();
    handleClose();
  };

  return (
    <ModalCloseButtons>
      <ButtonsGroupWrapper>
        <SaveAndCloseGallery />
        <NextAndBackButtons
          handleLeftClick={handleLeftClick}
          handleRightClick={handleRightClick}
        />
      </ButtonsGroupWrapper>
    </ModalCloseButtons>
  );
};
