import { Grid } from '@material-ui/core';
import React from 'react';
import { useGalleryContent } from '../../../hooks/useGalleryContent';
import {
  FifthMediumTextMessageMobile,
  FifthMediumTextMobile,
  FifthTitleMobile,
  GridWithBorderR,
  ImageContainerFifthMobile,
} from './styled';
import { GalleryCover } from '../../../../../shared/types/commonGallery';

export const GalleryDemoFifthMobile: React.FC<GalleryCover> = (props) => {
  const { isPreview } = props;

  const data = useGalleryContent({
    ...props,
    size: 'mobile',
    galleryNumber: 'fifth',
  });

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ padding: '0 16px 0', height: '100%' }}
    >
      <Grid container item xs={12} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <FifthTitleMobile isPreview={!!isPreview}>
            {data.name}
          </FifthTitleMobile>
        </Grid>
        <Grid item xs={12}>
          <ImageContainerFifthMobile isPreview={!!isPreview}>
            {data.file}
          </ImageContainerFifthMobile>
        </Grid>
        <Grid item xs={12}>
          <FifthMediumTextMessageMobile isPreview={!!isPreview}>
            {data.message}
          </FifthMediumTextMessageMobile>
        </Grid>
        <Grid container item xs={12}>
          <GridWithBorderR
            item
            xs={5}
            style={{ border: '1' }}
            container
            alignItems="center"
          >
            <FifthMediumTextMobile isPreview={!!isPreview}>
              {data.date}
            </FifthMediumTextMobile>
          </GridWithBorderR>
          <Grid
            container
            item
            xs={7}
            justifyContent="flex-end"
            alignItems="center"
          >
            <FifthMediumTextMobile isPreview={!!isPreview}>
              {data.userName}
            </FifthMediumTextMobile>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
