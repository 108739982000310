import React, {
  Dispatch, SetStateAction, ReactNode,
} from 'react';
import { useFormikContext } from 'formik';
import { ButtonMoveToTop } from '../../../../../../../shared/components/ButtonMoveToTop/ButtonMoveToTop';
import { useGetGalleryCover } from '../../../../../../GalleryView/hooks/useGetGalleryCover';
import { GalleryPreviewButtonsGroup } from './GalleryPreviewButtonsGroup';
import { GalleryPreviewHeader } from './GalleryPreviewHeader';
import { GalleryContent } from '../../../../../../GalleryView/components/GalleryContent/GalleryContent';
import { GalleryDemoWindow } from './styled';
import { GalleryFooter } from '../../../../../../GalleryView/components/GalleryContent/GalleryFooter/GalleryFooter';
import { useCheckWindowSize } from '../../../../../../../shared/hooks/useCheckWindowSize';
import { GalleryCover } from '../../../../../../../shared/types/commonGallery';
import { CreatorGalleryForm } from '../../../../../../../shared/types/creatorGallery';
import { ClientGalleryGet } from '../../../../../../../shared/types/clientGallery';

type ModalWindowProps = {
  isOpen?: boolean;
  button?: ReactNode;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  clickStep: (step: number) => void;
};

export const ModalWindowGalleryPreview: React.FC<ModalWindowProps> = ({
  isOpen = false,
  button,
  setIsOpen,
  clickStep,
}) => {
  const { screenSize } = useCheckWindowSize();
  const { values } = useFormikContext<CreatorGalleryForm>();
  const props: GalleryCover = {
    name: values.name,
    date: values.date,
    file:
      values?.coverFile
        ? (values?.coverFile[screenSize] || values?.coverFile.original)
        : null,
    userName: values?.creatorBusinessName,
    message: values.message,
  };
  const galleryCover = useGetGalleryCover(props, values.galleryDesign || 1);

  const handleClose = () => setIsOpen(false);

  return (
    <>
      {button}
      <GalleryDemoWindow
        fullScreen
        open={isOpen}
        onClose={handleClose}
      >
        <div id="scrolled-container">
          <GalleryPreviewHeader>
            <GalleryPreviewButtonsGroup clickStep={clickStep} handleClose={handleClose} />
          </GalleryPreviewHeader>
          <div style={{ boxSizing: 'border-box', padding: '0px 40px' }}>
            <div style={{
              display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center',
            }}
            >
              {galleryCover}
            </div>
            <GalleryContent gallery={values as ClientGalleryGet} />
            <div style={{ width: '100%', margin: '0 auto' }}>
              <GalleryFooter branding={!values.removeBranding} />
            </div>
          </div>
        </div>
        <ButtonMoveToTop visibleImportant margins />
      </GalleryDemoWindow>
    </>
  );
};
