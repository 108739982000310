import React, { ChangeEvent } from 'react';
import { CustomFile } from '../../../types/commonGallery';
import { ErrorMessage } from '../../styled';
import { InputAndErrorWrapper, InputAndLabelWrapper } from '../styled';
import { AddFileLabel, CommonFileUploadPlus } from './styled';
import { TextLabel } from '../TextLabel';
import { getFileInContainer } from '../../../utils/gallery/getFileInContainer';
import { FileLoader } from '../../Loaders/FileLoader/FileLoader';
import { ProgressLoader } from '../../Loaders/ProgressLoader/ProgressLoader';
import { FileLoaderWrapper } from '../../Loaders/FileLoader/styled';

type CommonInputProps = {
  error?: string | any;
  value: CustomFile | null;
  name: string;
  handleChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  touched?: boolean | any;
  label?: string;
  accept?: string;
  id?: string;
  isLoading?: boolean;
  isLoadingWithProgress?: boolean;
  progress?: number;
};

export const CommonFileUpload: React.FC<CommonInputProps> = ({
  error,
  value,
  name,
  handleChange,
  handleBlur,
  touched,
  label,
  accept = '*',
  id,
  isLoading,
  isLoadingWithProgress,
  progress,
}) => {
  const file = getFileInContainer({
    file: value, isLoading, isLoadingWithProgress, progress,
  });

  return (
    <InputAndLabelWrapper id={id} direction="column">
      <TextLabel name={name} label={label} />
      <InputAndErrorWrapper>
        <AddFileLabel
          imageUrl={value?.url || ''}
          hasFile={!!value?.url}
          hasError={!!(error && touched)}
        >
          {isLoadingWithProgress && <FileLoaderWrapper id="loader" isLittle={false}><ProgressLoader value={progress || 0} /></FileLoaderWrapper>}
          <FileLoader isLittle isLoading={!!isLoading} />
          {value ? file : null}
          <CommonFileUploadPlus hasFile={!!value?.url} id="input-background" />
          <input
            id={name}
            name={name}
            type="file"
            onChange={handleChange}
            onBlur={handleBlur}
            accept={accept}
            disabled={isLoading}
          />
        </AddFileLabel>
        <ErrorMessage>{error && touched ? error : null}</ErrorMessage>
      </InputAndErrorWrapper>
    </InputAndLabelWrapper>
  );
};
