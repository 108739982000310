import { Grid } from '@material-ui/core';
import React, {
  MouseEvent, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik, useFormikContext } from 'formik';
import { useAuth } from '@clerk/clerk-react';
import { CommonInput } from '../../../../../../../shared/components/FormElements/CommonInput/CommonInput';
import { FormGalleryWrapper, GridWrapper } from '../styled';
import { CommonCopyInput } from '../../../../../../../shared/components/CommonCopyInput/CommonCopyInput';
import {
  LittleBlackText,
  SimpleBlackText,
} from '../../../../../../../shared/styles';
import { TickIcon } from '../../../../../../../shared/icons';
import { SendLinkButtonWrapper, SentLinkWrapper, SharableLinkWrapper } from './styled';
import { clientSchema } from '../../../../../../../shared/validation/gallery/clientSchema';
import { clientInitialValues } from '../../../../../../../constants/initialValues';
import { PrimaryWhiteButton } from '../../../../../../../shared/styles/Buttons';
import { gallery } from '../../../../../../../api/gallery/gallery';
import { InvitePostDto } from '../../../../../types/gallery';
import { PinkLoader } from '../../../../../../../shared/components/Loaders/PinkLoader/PinkLoader';
import { ErrorMessage } from '../../../../../../../shared/components/styled';
import { CreatorGalleryForm } from '../../../../../../../shared/types/creatorGallery';
import { Client } from '../../../../../../../shared/types/commonGallery';

type SendLinkProps = {
  isLoading: boolean;
  error: string;
  wasSent: boolean;
  sendLink: (data: InvitePostDto) => void;
}

export const useSendLink = (): SendLinkProps => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [wasSent, setWasSent] = useState(false);
  const { getToken } = useAuth();

  const sendLink = async (data: InvitePostDto) => {
    const token = (await getToken()) || '';
    gallery.sendInvite(data, token)
      .then(() => {
        setWasSent(true);
        setTimeout(() => setWasSent(false), 5000);
      })
      .catch((err) => { console.log({ err }); setWasSent(false); setError(err.message); })
      .finally(() => {
        setIsLoading(false);
        setError('');
      });
  };

  return {
    isLoading,
    error,
    wasSent,
    sendLink,
  };
};

export const NewGalleryCreatorFifthStep: React.FC = () => {
  const { t } = useTranslation('createNewGallery');
  const refWidth = useRef<HTMLInputElement>(null);
  const {
    isLoading, error, wasSent, sendLink,
  } = useSendLink();
  const { values: globalValues } = useFormikContext<CreatorGalleryForm>();

  const {
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
  } = useFormik<Client>({
    validationSchema: clientSchema,
    initialValues: clientInitialValues,
    onSubmit: (data: Client) => {
      const fullGalleryLink = process.env.NODE_ENV === 'development'
        ? `http://${globalValues.galleryUrl}`
        : `https://${globalValues.galleryUrl}`;

      sendLink({ email: data.email, url: fullGalleryLink });
    },
  });

  const handleSendLink = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSubmit();
  };

  return (
    <>
      <FormGalleryWrapper>
        <SharableLinkWrapper id="sharable-link-fifth-step">
          <Grid
            container
            item
            sm={9}
            spacing={1}
            alignItems="center"
            style={{ width: refWidth.current?.offsetWidth }}
          >
            <Grid item sm={12}>
              <SimpleBlackText>{t('fifthStep.sharableLink')}</SimpleBlackText>
            </Grid>
          </Grid>
          <div>
            <CommonCopyInput
              value={globalValues.galleryUrl}
              name="galleryUrl"
              readonly
              isLinkFull
            />
          </div>
        </SharableLinkWrapper>
      </FormGalleryWrapper>
      <FormGalleryWrapper>
        <GridWrapper container spacing={1}>
          <Grid
            container
            item
            sm={3}
            spacing={1}
            alignItems="flex-start"
            ref={refWidth}
          >
            <Grid item sm={12}>
              <SimpleBlackText>{t('fifthStep.sendClient')}</SimpleBlackText>
            </Grid>
          </Grid>
          <Grid container item sm={8} direction="column" wrap="nowrap">
            <Grid item xl={6} lg={8} md={11}>
              <CommonInput
                error={errors.name}
                value={values.name}
                touched={touched.name}
                name="name"
                placeholder={t('fifthStep.name')}
                handleChange={handleChange}
                handleBlur={handleBlur}
                type="text"
              />
            </Grid>
            <Grid item xl={6} lg={8} md={11}>
              <CommonInput
                error={errors.email}
                value={values.email}
                name="email"
                placeholder={t('fifthStep.emailAddress')}
                handleChange={handleChange}
                handleBlur={handleBlur}
                touched={touched.email}
                type="text"
              />
            </Grid>
            <SendLinkButtonWrapper>
              <PrimaryWhiteButton type="submit" onClick={handleSendLink} disabled={isLoading}>
                {t('fifthStep.buttons.sendLink')}
              </PrimaryWhiteButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
              {isLoading && <PinkLoader size="xs" />}
              {wasSent && (
                <SentLinkWrapper>
                  <TickIcon />
                  <LittleBlackText>{t('fifthStep.sentLink')}</LittleBlackText>
                </SentLinkWrapper>
              )}
            </SendLinkButtonWrapper>
          </Grid>
        </GridWrapper>
      </FormGalleryWrapper>
    </>
  );
};
