import { AxiosResponse } from 'axios';
import { Cart, CartReq } from '../../../shared/types/client';
import { clientInstance } from '../../base/instance';
import { interactionsUrl } from '../../../constants/urls';

export const cart = {
  getCart: (galleryId: string): Promise<AxiosResponse<Cart>> => clientInstance.get(`${interactionsUrl}/client/cart/${galleryId}`),
  addToCart: (data: CartReq): Promise<AxiosResponse<any>> => clientInstance.put(`${interactionsUrl}/cart`, data),
  removeCartItem: (data: CartReq): Promise<AxiosResponse<string[]>> => clientInstance.put(`${interactionsUrl}/cart/delete`, data),
};
