import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../../../shared/icons';
import { CommentArea } from './CommentArea';
import {
  CloseButtonWrapper,
  CloseCommentsButton,
  CommentContainerWrapper,
} from './styled';
import { GalleryComment } from '../../../../shared/types/commonGallery';

type CommentAreaProps = {
  comments: GalleryComment[];
  handleClose: () => void;
  dateFormat: string;
  addComment: (comment: string) => void;
  deleteComment: (commentId: string) => void;
  userProfileUrl?: string
};

export const CommentContainer: React.FC<CommentAreaProps> = ({
  comments,
  handleClose,
  dateFormat,
  addComment,
  deleteComment,
  userProfileUrl,
}) => {
  const { t } = useTranslation('gallery');

  return (
    <CommentContainerWrapper id="comments-container">
      <CloseButtonWrapper>
        <CloseCommentsButton type="button" onClick={handleClose}>
          <CloseIcon />
          <div>{t('closeComments')}</div>
        </CloseCommentsButton>
      </CloseButtonWrapper>
      <CommentArea
        userProfileUrl={userProfileUrl}
        addComment={addComment}
        deleteComment={deleteComment}
        comments={comments}
        dateFormat={dateFormat}
      />
    </CommentContainerWrapper>
  );
};
