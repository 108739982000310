import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import {
  SixthMediumTextTablet,
  SixthTitleTablet,
  ImageContainerSixthTablet,
  SixthInfoCardCoverTablet,
  WrapperMarginBTablet,
} from './styled';
import { useGalleryContent } from '../../../hooks/useGalleryContent';
import { GalleryCover } from '../../../../../shared/types/commonGallery';

export const GalleryDemoSixthTablet: React.FC<GalleryCover> = (props) => {
  const refCardTablet = useRef<HTMLDivElement>(null);
  const { isPreview } = props;

  const data = useGalleryContent({
    ...props,
    size: 'tablet',
    galleryNumber: 'sixth',
  });

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{ height: '100%' }}
    >
      <Grid item sm={12}>
        <ImageContainerSixthTablet isPreview={!!isPreview}>
          {data.file}
          <SixthInfoCardCoverTablet
            isPreview={!!isPreview}
            ref={refCardTablet}
            currentHeight={refCardTablet?.current?.offsetHeight || 0}
          >
            <SixthTitleTablet isPreview={!!isPreview}>{data.name}</SixthTitleTablet>
            <WrapperMarginBTablet isPreview={!!isPreview}>
              <SixthMediumTextTablet isPreview={!!isPreview}>{data.message}</SixthMediumTextTablet>
              <SixthMediumTextTablet isPreview={!!isPreview}>{data.userName}</SixthMediumTextTablet>
            </WrapperMarginBTablet>
            <SixthMediumTextTablet isPreview={!!isPreview}>{data.date}</SixthMediumTextTablet>
          </SixthInfoCardCoverTablet>
        </ImageContainerSixthTablet>
      </Grid>
    </Grid>
  );
};
