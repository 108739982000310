import { Grid } from '@material-ui/core';
import { FormikErrors, FormikTouched } from 'formik';
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonPriceInput } from '../../../../../../../shared/components/FormElements/CommonPriceInput/CommonPriceInput';
import { CommonSwitch } from '../../../../../../../shared/components/FormElements/CommonSwitch/CommonSwitch';
import { CommonTextarea } from '../../../../../../../shared/components/FormElements/CommonTextarea/CommonTextarea';
import {
  SetFieldValue,
} from '../../../../../../../shared/types';
import {
  isArrayIncludeValue,
  isVideo,
} from '../../../../../../../shared/utils/commonFunctions';
import { VideoThumbnail } from './VideoThumbnail';
import {
  setValues,
  unsetValues,
} from '../../../../../redux/gallery/fileSettings/fileSettingsCreatorSlice';
import { areObjectsSimilar } from '../../../../../utils/helpers';
import { getGalleryFileSettings } from '../../../../../../../constants/initialValues';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';
import { GalleryFileSettings, GalleryIdea } from '../../../../../../../shared/types/commonGallery';
import { CreatorGallerySection } from '../../../../../../../shared/types/creatorGallery';

type FilesFormProps = {
  selected: string[];
  values: GalleryFileSettings;
  handleChange: (e: ChangeEvent<any>) => void;
  handleBlur: (e: ChangeEvent<any>) => void;
  setFieldValue: SetFieldValue;
  errors: FormikErrors<GalleryFileSettings>;
  touched: FormikTouched<GalleryFileSettings>;
  section: CreatorGallerySection;
  disabledInputs: boolean;
  initialValues: GalleryFileSettings;
};

export const FilesForm: React.FC<FilesFormProps> = ({
  selected,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
  section,
  setFieldValue,
  disabledInputs,
  initialValues,
}) => {
  const { t } = useTranslation('createNewGallery');
  const dispatch = useCreatorAppDispatch();
  const selectedIdeas = useMemo(() => {
    const ideas = section.ideas as GalleryIdea[];
    return ideas.filter((item) => isArrayIncludeValue(selected, item.id));
  }, [selected, section.ideas]);

  const isAllIdeas = selected.length === selectedIdeas.length;
  const isAllVideos = useMemo(() => {
    const videos = section?.files?.filter((item) => isVideo(item));
    const selectedVideos = videos.filter((item) => isArrayIncludeValue(selected, item.id));
    return selectedVideos?.length === selected?.length;
  }, [selected, section.files]);

  useEffect(() => {
    if (values.instantlyDownloadable) {
      setFieldValue('price', '');
    }
  }, [values.instantlyDownloadable]);

  useEffect(() => {
    if (selected.length) {
      if (!areObjectsSimilar(values, getGalleryFileSettings(section, selected))) {
        dispatch(
          setValues({ selected, sectionId: section.id, settings: values }),
        );
      } else {
        dispatch(unsetValues({ sectionId: section.id }));
      }
    } else {
      dispatch(unsetValues({ sectionId: section.id }));
    }
  }, [values, initialValues, selected.length]);

  return (
    <>
      <Grid item sm={12}>
        <CommonTextarea
          name="description"
          handleChange={handleChange}
          touched={touched.description}
          error={isAllIdeas ? '' : errors.description}
          value={values.description}
          placeholder={t('thirdStep.description')}
          disabled={selected?.length === 0 || isAllIdeas || disabledInputs}
        />
      </Grid>
      <Grid item sm={12}>
        <CommonPriceInput
          justifyContent="space-between"
          inputWidth={180}
          inputAndErrorWrapperStyle={{ alignItems: 'flex-end' }}
          label={t('thirdStep.price')}
          value={values.price}
          name="price"
          setFieldValue={setFieldValue}
          direction="row"
          error={isAllIdeas ? '' : errors.price}
          touched={touched.price}
          disabled={selected?.length === 0
            || isAllIdeas
            || values.instantlyDownloadable
            || disabledInputs}
        />
      </Grid>
      <Grid item sm={12}>
        <CommonSwitch
          labelWhiteSpace="pre"
          textLabelStyles={{ whiteSpace: 'pre' }}
          isChecked={!!values.instantlyDownloadable}
          handleChange={handleChange}
          name="instantlyDownloadable"
          label={t('thirdStep.downloadable')}
          disabled={selected?.length === 0 || disabledInputs || section.disableDownloads}
          shortLabel
          helpText={t('thirdStep.helpTexts.downloadable')}
          withoutMargins
          spaceBetween
        />
      </Grid>
      <Grid item sm={12}>
        <CommonSwitch
          isChecked={!!values.watermark}
          handleChange={handleChange}
          name="watermark"
          label={t('thirdStep.watermark')}
          helpText={t('thirdStep.helpTexts.watermark')}
          disabled={selected?.length === 0 || disabledInputs}
          withoutMargins
          spaceBetween
        />
      </Grid>
      <Grid item sm={12}>
        <CommonSwitch
          isChecked={!!values.allowCommenting}
          handleChange={handleChange}
          name="allowCommenting"
          label={t('thirdStep.allowCommenting')}
          disabled={selected?.length === 0 || disabledInputs}
          helpText={t('thirdStep.helpTexts.allowCommenting')}
          withoutMargins
          spaceBetween
        />
      </Grid>
      <VideoThumbnail
        videoId={selected[0]}
        setFieldValue={setFieldValue}
        errors={errors}
        touched={touched}
        selected={selected}
        values={values}
        handleChange={handleChange}
        section={section}
        disabled={disabledInputs}
      />
    </>
  );
};
