/* eslint-disable */
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { SimpleBlackText } from '../../../../../../../../shared/styles';
import noPhoto from '../../../../../../../../assets/image/noPhoto.png';
import { ProfilePictureWrapper, UpdatePictureButton } from './styled';
import { ChangePhotoWindow } from './ChangePhotoWindow';
import { getURLByFile } from '../../../../../../../../shared/utils';
import { useUser } from '@clerk/clerk-react';
import { GalleryFileBaseWithUrls } from '../../../../../../../../shared/types/commonGallery';

type SetProfileImage = {
  originalPhoto: { file: GalleryFileBaseWithUrls & { file: File | null } };
  croppedPhoto: { file: (GalleryFileBaseWithUrls & { file: File | null }) | null };
};

export const ProfilePicture: React.FC = () => {
  const { t } = useTranslation('settings');
  const [isModalWindowOpen, setIsModalWindowOpen] = useState(false);
  const { user } = useUser();

  const initialImageObj = {
    file: {
      name: 'profile',
      url: user?.hasImage ? user.imageUrl : noPhoto,
      type: 'image',
      id: 'profile',
      file: null,
    }
  };

  const onSubmit = (data: SetProfileImage) => {
    console.log('submit');
  };
  const { values, errors, touched, setFieldValue } =
    useFormik<SetProfileImage>({
      initialValues: {
        originalPhoto: initialImageObj,
        croppedPhoto: {
          file: {
                name: 'profile',
                url: user?.hasImage ? user.imageUrl : noPhoto,
                type: 'image',
                id: 'profile',
                file: null,
        },
      }
      },
      onSubmit: (data) => onSubmit(data),
    });

  const handleUploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    if (event.currentTarget.files) {
      const newImage = event.currentTarget.files || [];

      setFieldValue('originalPhoto', {
        file: {
          name: 'profile',
          url: getURLByFile(newImage[0]),
          type: 'image',
          file: newImage[0],
        },
      });
      setIsModalWindowOpen(true);
    }
  };

  return (
    <div>
      <SimpleBlackText>{t('profilePicture')}</SimpleBlackText>
      <div>
        <ProfilePictureWrapper
          imageUrl={values?.originalPhoto?.file?.url || ''}
          hasFile={!!values?.originalPhoto?.file?.url}
          hasError={!!(errors.originalPhoto && touched.originalPhoto)}
        >
          <input name="originalPhoto" type="file" onChange={handleUploadFile} accept="image" />
        </ProfilePictureWrapper>
      </div>
      <UpdatePictureButton
        type="button"
        disabled={!!(!values.originalPhoto || (errors.originalPhoto && touched.originalPhoto))}
        color="secondary"
      >
        <input name="originalPhoto" type="file" onChange={handleUploadFile} accept="image" />
        {t('changeProfilePicture')}
      </UpdatePictureButton>
      <ChangePhotoWindow
        setFieldValue={setFieldValue}
        isOpen={isModalWindowOpen}
        setIsOpen={setIsModalWindowOpen}
        image={values?.originalPhoto.file}
        initialImageObj={initialImageObj}
      />
    </div>
  );
};
