import { Grid } from '@material-ui/core';
import React from 'react';
import { useGalleryContent } from '../../../hooks/useGalleryContent';
import {
  FirstHeaderMobileWrapper,
  FirstMediumTextMobile,
  FirstTitleMobile,
  ImageContainerFirstMobile,
} from './styled';
import { GalleryCover } from '../../../../../shared/types/commonGallery';

export const GalleryDemoFirstMobile: React.FC<GalleryCover> = (props) => {
  const { isPreview } = props;
  const data = useGalleryContent({
    ...props,
    size: 'mobile',
    galleryNumber: 'first',
  });

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <FirstHeaderMobileWrapper isPreview={!!isPreview}>
          <FirstTitleMobile isPreview={!!isPreview}>{data.name}</FirstTitleMobile>
        </FirstHeaderMobileWrapper>
      </Grid>
      <Grid item xs={12}>
        <ImageContainerFirstMobile isPreview={!!isPreview}>
          {data.file}
        </ImageContainerFirstMobile>
      </Grid>
      <Grid item xs={12}>
        <FirstMediumTextMobile isPreview={!!isPreview}>{data.message}</FirstMediumTextMobile>
      </Grid>
      <Grid item xs={12}>
        <FirstMediumTextMobile isPreview={!!isPreview} style={{ marginBottom: '16px' }}>{data.userName}</FirstMediumTextMobile>
      </Grid>
      <Grid item xs={12}>
        <FirstMediumTextMobile isPreview={!!isPreview}>{data.date}</FirstMediumTextMobile>
      </Grid>
    </Grid>
  );
};
