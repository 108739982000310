/* eslint-disable max-len */
import axios, { AxiosResponse } from 'axios';
import { ClientGalleryReq } from '../../shared/types/client';
import { clientInstance } from '../base/instance';
import { ClientGalleryGetResponse } from '../../shared/types/clientGallery';
import { GalleryCreatorSocialMedia } from '../../shared/types/commonGallery';

const awsDomain = `${process.env.REACT_APP_BASE_MEDIA_API_URL}/`;
const azpToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiYXpwIjoiaHR0cHM6Ly9kZWxpdnJhYmxlLmlvIiwiaWF0IjoxNTE2MjM5MDIyfQ.rOtAw5usEBKrWcSe5l5EeHU2QGoGCI0GECChfEJV29g';

export const clientGallery = {
  getGallery: (data: ClientGalleryReq): Promise<AxiosResponse<{ gallery: ClientGalleryGetResponse, email: string, wasWatched: boolean, socialMedia: GalleryCreatorSocialMedia }>> => clientInstance.get(`client/gallery/${data.businessName}/${data.galleryName}`),
  checkGalleryLogin: (data: ClientGalleryReq): Promise<AxiosResponse<{ password: boolean }>> => clientInstance.get(`client/gallery/check/${data.businessName}/${data.galleryName}`),
};

export const downloadFiles = {
  getDownloadSingleFileUrl: (galleryId: string, fileId: string): Promise<AxiosResponse<any>> => clientInstance.get(`client/download/${galleryId}/${fileId}`),
  getArchiveUrl: (awsKeys: string[], orderId: string): Promise<AxiosResponse> => axios.post(`${awsDomain}generate-archive`, { orderId, awsKeys }, { headers: { Authorization: `Bearer ${azpToken}` } }),
};
