import { isArrayEmpty, isImage, isVideo } from '..';
import { ClientOrCreatorGalleryFile, Filters } from '../../types';

export const getImagesAndVideosFiltered = (
  chosenFilters: Filters[],
  imagesAndVideos: ClientOrCreatorGalleryFile[],
): ClientOrCreatorGalleryFile[] => {
  if (
    isArrayEmpty(chosenFilters)
      || (chosenFilters.includes('photos') && chosenFilters.includes('videos'))
  ) {
    return imagesAndVideos;
  }
  if (chosenFilters.includes('photos')) {
    return imagesAndVideos.filter((item) => isImage(item));
  }
  if (chosenFilters.includes('videos')) {
    return imagesAndVideos.filter((item) => isVideo(item));
  }
  return [];
};
