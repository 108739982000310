import React from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleTooltip } from '../../../../../../../../../shared/components/HelpTip/SimpleTooltip';
import {
  DownloadIcon,
  HeartIcon,
} from '../../../../../../../../../shared/icons';
import {
  LikesOrDownloadsFileWrapper,
  LikesOrDownloadsIconWrapper,
  LikesOrDownloadsWrapper,
} from './styled';
import { SimpleBlackText } from '../../../../../../../../../shared/styles';
import { getFileInContainer } from '../../../../../../../../../shared/utils/createGallery';
import { isIdea } from '../../../../../../../../../shared/utils';
import { CreatorGalleryFile, FavouritesCartResItem, GalleryIdea } from '../../../../../../../../../shared/types/commonGallery';

type LikesOrDownloadsProps = {
  likesOrDownloads: FavouritesCartResItem[];
  type: 'like' | 'download';
};

export const LikesOrDownloadsTab: React.FC<LikesOrDownloadsProps> = ({
  likesOrDownloads,
  type,
}) => {
  const { t } = useTranslation('gallery');

  if (!likesOrDownloads?.length) { return <SimpleBlackText>{t('noLikes')}</SimpleBlackText>; }

  return (
    <LikesOrDownloadsWrapper>
      {likesOrDownloads.map((item) => (
        <LikesOrDownloadsFileWrapper key={item.id}>
          {getFileInContainer({
            file: isIdea(item) ? (item as GalleryIdea).cover : (item as CreatorGalleryFile),
          })}
          <LikesOrDownloadsIconWrapper style={{ background: 'transparent' }}>
            {type === 'download' ? <DownloadIcon /> : <HeartIcon />}
            <SimpleTooltip text={item.email || 'Anonym'} relativeWidth={20} />
          </LikesOrDownloadsIconWrapper>
        </LikesOrDownloadsFileWrapper>
      ))}
    </LikesOrDownloadsWrapper>
  );
};
