import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useAuth } from '@clerk/clerk-react';
import { SimpleBlackText } from '../../../../../../shared/styles';
import { GalleryCard } from '../../../../../../shared/components/GalleryCard/GalleryCard';
import * as urls from '../../../../../../constants/urls';
import { fetchDeleteCreatorGallery } from '../../../../redux/gallery/galleryCreatorThunk';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../shared/hooks/useTypedSelector';
import { CreatorGalleryGet } from '../../../../../../shared/types/creatorGallery';

type DraftGalleriesCardsProps = {
  galleries: CreatorGalleryGet[];
};

export const DraftGalleriesCards: React.FC<DraftGalleriesCardsProps> = ({
  galleries,
}) => {
  const { t } = useTranslation('draftGalleries');
  const navigate = useNavigate();
  const dispatch = useCreatorAppDispatch();
  const creator = useTypedSelectorCreator((state) => state.creator?.currentUser);
  const { getToken } = useAuth();

  const handleClick = (id: string) => () => {
    navigate(`${urls.newGalleryUrl}/${id}`);
  };

  const handleDelete = (galleryId: string) => {
    dispatch(fetchDeleteCreatorGallery({ id: galleryId, getToken }));
  };

  return (
    galleries.length ? (
      galleries.map((item) => (
        <Grid item lg={6} md={12} key={item.id}>
          <GalleryCard
            title={item.name}
            coverFile={{
              id: item?.coverFile?.desktop?.id || item?.coverFile?.original?.id || '',
              type: item?.coverFile?.desktop?.type || item?.coverFile?.original?.type || 'image/jpeg',
              name: item?.coverFile?.desktop?.name || (item?.coverFile?.original?.name ? item?.coverFile?.original?.name ?? 'cover' : 'cover'),
              url: item?.coverFile?.desktop?.url || item?.coverFile?.original?.url || '',
            }}
            buttonText={t('continueEditing')}
            handleClick={creator?.onboarded ? handleClick(item.id) : undefined}
            handleDelete={handleDelete}
            galleryId={item.id}
          />
        </Grid>
      ))
    ) : (
      <Grid item sm={6}>
        <SimpleBlackText>{t('noDraftGalleries')}</SimpleBlackText>
      </Grid>
    )
  );
};
