import React, {
  useContext, useEffect, useState,
} from 'react';
import { useFormikContext } from 'formik';
import { ModalWindowGalleryPreview } from '../NewGalleryCreatorFifthStep/ModalWindowGalleryPreview';
import { SaveAndCloseGallery } from './SaveAndCloseGallery/SaveAndCloseGallery';
import { CreatorOnboardingContext } from '../../../../../context/CreatorOnboardingContext';
import { CreatorOnboardingStepIndexContext } from '../../../../../context/CreatorOnboardingStepIndexContext';
import { NextAndBackButtons } from './NextAndBackButtons';
import { NewGalleryCreatorButtonsWrapper } from './styled';
import { setSuccessDeleteFalse, setSuccessSaveFalse } from '../../../../../redux/gallery/galleryCreatorSlice';
import { resetUploadingFiles, resetErrorFiles, resetFiles } from '../../../../../redux/gallery/uploadFiles/uploadFilesSliceV2';
import { useCreatorAppDispatch } from '../../../../../../../shared/hooks/useTypedSelector';
import { CreatorGalleryForm } from '../../../../../../../shared/types/creatorGallery';

type NewGalleryCreatorButtonsProps = {
  step: number;
  clickSteps: (step: number) => void;
};

const goToNextStageDuringOnboarding = (stepIndex: number) => (
  stepIndex === 9 || stepIndex === 16 || stepIndex === 22);
const goToPrevStageDuringOnboarding = (stepIndex: number) => (
  stepIndex === 8 || stepIndex === 15 || stepIndex === 21);
const openGalleryPreviewDuringOnboarding = (
  stepIndex: number,
  isOpen: boolean,
) => (stepIndex === 29 || stepIndex === 31) && !isOpen;
const closeGalleryPreviewDuringOnboarding = (
  stepIndex: number,
  isOpen: boolean,
) => (stepIndex === 28 || stepIndex === 32) && isOpen;

export const NewGalleryCreatorButtons: React.FC<
  NewGalleryCreatorButtonsProps
> = ({ step, clickSteps }) => {
  const [isOpenGalleryPreview, setIsOpenGalleryPreview] = useState(false);
  const {
    values,
    handleSubmit,
    isValid,
  } = useFormikContext<CreatorGalleryForm>();

  const dispatch = useCreatorAppDispatch();
  const { isStudy } = useContext(CreatorOnboardingContext);
  const { stepIndex } = useContext(CreatorOnboardingStepIndexContext);

  useEffect(() => {
    if (isStudy) {
      if (goToNextStageDuringOnboarding(stepIndex)) {
        const nextStage = step + 1;
        clickSteps(nextStage);
        handleSubmit();
      }
      if (goToPrevStageDuringOnboarding(stepIndex)) {
        const prevStage = step - 1;
        clickSteps(prevStage);
        handleSubmit();
      }
      if (openGalleryPreviewDuringOnboarding(stepIndex, isOpenGalleryPreview)) {
        setIsOpenGalleryPreview(true);
      }
      if (
        closeGalleryPreviewDuringOnboarding(stepIndex, isOpenGalleryPreview)
      ) {
        setIsOpenGalleryPreview(false);
      }
    }
    return () => {
      dispatch(setSuccessSaveFalse());
      dispatch(resetUploadingFiles());
      dispatch(setSuccessDeleteFalse());
      dispatch(resetErrorFiles());
      dispatch(resetFiles());
    };
  }, [stepIndex]);

  const handleLeftClick = () => {
    if (isValid) {
      if (values.step === 5) {
        setIsOpenGalleryPreview(true);
      } else if (values.step > 1) {
        const prevStage = step - 1;
        clickSteps(prevStage);
        handleSubmit();
      }
    }
  };
  const handleRightClick = () => {
    if (isValid) {
      if (values.step === 4) {
        setIsOpenGalleryPreview(true);
      } else if (values.step < 5) {
        const nextStage = step + 1;
        clickSteps(nextStage);
        handleSubmit();
      }
    }
  };

  return (
    <>
      <NewGalleryCreatorButtonsWrapper>
        {step < 5 && <SaveAndCloseGallery />}
        <NextAndBackButtons
          handleLeftClick={handleLeftClick}
          handleRightClick={handleRightClick}
        />
        {step >= 5 && <SaveAndCloseGallery />}
      </NewGalleryCreatorButtonsWrapper>
      <ModalWindowGalleryPreview
        clickStep={clickSteps}
        setIsOpen={setIsOpenGalleryPreview}
        isOpen={isOpenGalleryPreview}
      />
    </>
  );
};
