import { Dispatch } from 'react';
import { v4 as uuidv4 } from 'uuid';
import {
  fetchAddComment,
  fetchDeleteComment,
} from '../../../modules/GalleryView/redux/comments/commentsThunk';
import { getSectionId } from '../../../modules/GalleryView/utils/helpers';
import { getFormatDateForDob } from '../dateOperations';
import { ClientGalleryGet } from '../../types/clientGallery';
import { CreatorGalleryGet } from '../../types/creatorGallery';

type AddFuncReturn = (comment: string) => void;
type DeleteFuncReturn = (commentId: string) => void;

export const getAddCommentFunc = (
  dispatch: Dispatch<any>,
  gallery: ClientGalleryGet | CreatorGalleryGet | null | undefined,
  fileId: string,
): AddFuncReturn => {
  const resFunc = (comment: string) => {
    if (gallery) {
      const sectionId = getSectionId(gallery, fileId);
      dispatch(
        fetchAddComment({
          galleryId: gallery?.id || '',
          sectionId,
          fileId,
          messageId: uuidv4(),
          text: comment,
          date: getFormatDateForDob(new Date()),
        }),
      );
    }
  };
  return resFunc;
};

export const getDeleteCommentFunc = (
  dispatch: Dispatch<any>,
  galleryId: string,
  fileId: string,
): DeleteFuncReturn => (commentId: string) => {
  if (galleryId) {
    dispatch(
      fetchDeleteComment({
        messageId: commentId,
        fileId,
        galleryId,
      }),
    );
  }
};
