import { useAuth } from '@clerk/clerk-react';
import { useCreatorAppDispatch, useTypedSelectorCreator } from '../../../../../../../shared/hooks/useTypedSelector';
import { fetchUpdateFileSettings } from '../../../../../redux/gallery/fileSettings/fileSettginsCreatorThunk';
import { GalleryFileUpdate } from '../../../../../../../shared/types/commonGallery';

type ReturnObj = {
  updateFiles: (files: GalleryFileUpdate[]) => void;
  isSuccess: boolean;
  isLoading: boolean;
  error: string;
};

export const useUpdateFiles = (): ReturnObj => {
  const dispatch = useCreatorAppDispatch();
  const isSuccess = useTypedSelectorCreator((state) => state.fileSettingsCreator.isSuccess);
  const isLoading = useTypedSelectorCreator((state) => state.fileSettingsCreator.loading);
  const error = useTypedSelectorCreator((state) => state.fileSettingsCreator.error);
  const { getToken } = useAuth();

  const updateFiles = async (files: GalleryFileUpdate[]) => {
    dispatch(fetchUpdateFileSettings({ files, getToken }));
  };

  return ({
    isLoading,
    error,
    isSuccess,
    updateFiles,
  });
};
