import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ErrorType } from '../../../../../shared/types';
import { Favourites } from '../../../../../shared/types/client';
import {
  fetchGetCreatorDownloads,
} from './downloadsCreatorThunk';
import { FavouritesCartResItem } from '../../../../../shared/types/commonGallery';

type initialState = {
  count: number;
  files: FavouritesCartResItem[];
  loadingGet: boolean;
  loadingDelete: boolean;
  error: ErrorType;
};

export const initialState: initialState = {
  count: 0,
  files: [],
  loadingGet: false,
  loadingDelete: false,
  error: null,
};

const downloadsCreatorSlice = createSlice({
  name: 'downloadsCreatorSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchGetCreatorDownloads.fulfilled,
      (state, action: PayloadAction<Favourites>) => {
        state.count = action.payload?.count;
        state.error = null;
        state.loadingGet = false;
        state.files = action.payload.files;
      },
    );
    builder.addCase(
      fetchGetCreatorDownloads.rejected,
      (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.loadingGet = false;
      },
    );
    builder.addCase(fetchGetCreatorDownloads.pending, (state) => {
      state.loadingGet = true;
    });
  },
});

export default downloadsCreatorSlice.reducer;
