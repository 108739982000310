import { ClientGalleryGet } from '../../types/clientGallery';
import { ClientGalleryFile, CreatorGalleryFile, GalleryIdea } from '../../types/commonGallery';
import { CreatorGalleryGet } from '../../types/creatorGallery';

export const getAllFiles = (
  gallery: CreatorGalleryGet | ClientGalleryGet | undefined,
): (
  ClientGalleryFile | GalleryIdea | CreatorGalleryFile
)[] => {
  let allFiles: (ClientGalleryFile | GalleryIdea)[] = [];
  if (!gallery) {
    return allFiles;
  }
  gallery?.sections?.forEach((item) => {
    allFiles = [...allFiles, ...item.files, ...item.ideas];
  });

  return allFiles;
};
