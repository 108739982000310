/* eslint-disable */
import React, { useCallback } from 'react';
import {
  SetFieldValue,
} from '../types';
import { getCroppedImg } from '../utils/createGallery';
import { getURLByFile } from '../utils/commonFunctions';
import { ExtraImageSize, GalleryFileBaseWithUrls, ImageSize } from '../types/commonGallery';

type ReturnSaveCroppedImageType = (
  fieldName: string,
  image: GalleryFileBaseWithUrls | null,
  size: ImageSize | 'profile' | ExtraImageSize
) => Promise<File | undefined>;

export const useSaveCroppedImage = (
  setFieldValue: SetFieldValue,
): ReturnSaveCroppedImageType => {
  const saveCroppedImage = useCallback(
    async (
      fieldName: string,
      image: GalleryFileBaseWithUrls | null,
      size: ImageSize | 'profile' | ExtraImageSize,
    ) => {
      const localStorageItems = { ...window.localStorage };

      try {
        const parsedLocalStorageItem = JSON.parse(localStorageItems[`${size}`]);
        if (localStorageItems[`${size}`] && image) {
          const newCroppedImage = await getCroppedImg(
            image,
            fieldName,
            parsedLocalStorageItem,
          );
          setFieldValue(fieldName, {
            file: {
              url: getURLByFile(newCroppedImage),
              type: newCroppedImage.type,
              name: newCroppedImage.name,
            },
            ...parsedLocalStorageItem,
          });

          return newCroppedImage;
        }
      } catch (e) {
        console.error(e);
      }

      return;
    },
    [],
  );

  return saveCroppedImage;
};
